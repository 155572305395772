import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { DollarCircle, Note, Shapes, Tag } from 'iconsax-react';
import { connect } from 'react-redux';
import CategorySearch from '@app/src/Components/ExpenseReview/Common/CategorySearch/CategorySearch';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import { useGetFeaturesQuery, specificFeatureSelector } from '@app/src/api/profileApi';
import IconCheckGreen from '@app/src/assets/icon-check-green.svg?react';
import IconClose from '@app/src/assets/icon-close.svg?react';
import { EXPENSE_REVIEW_MODAL_TYPES } from '@app/src/constants/dashboardConstants';
import {
  bulkEditLoadingSelector,
  manualExpenseCategoryOptionsListSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { bulkEditBase } from '@app/src/services/bulkEditService';
import { colorNeutralMetallica, colorSecondary, colorSurface } from '@app/src/theme';

const BulkEditToolbar = ({
  selectedRows,
  setSelectedRows,
  categoryOptions,
  setExpenseReviewModalType,
  loading,
  bulkEditBase
}) => {
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);

  const { jobLevelExpenseSplitEnabled, isLoading: isFeatureFlagLoading } = useGetFeaturesQuery(null, {
    selectFromResult: (result) => {
      return {
        ...result,
        jobLevelExpenseSplitEnabled: specificFeatureSelector(result, 'job-level-expense-split')
      };
    }
  });

  const handleStatusMenuSelect = ({ currentTarget }) => {
    trackActivity('expense review: bulk edit actions', { action: 'status' });
    setStatusAnchorEl(currentTarget);
  };

  const handleStatusBulkEdit = async (value) => {
    setStatusAnchorEl(null);
    await bulkEditBase({ type: 'status', value });
  };

  const handleCategoryMenuSelect = async ({ currentTarget }) => {
    trackActivity('expense review: bulk edit actions', { action: 'category' });
    setCategoryAnchorEl(currentTarget);
  };

  /**
   * The category search allows for multiple selections when filtering the table,
   * stored as an array of category ids. For bulk edit, since only one category can
   * be selected, we need to pull out the first item in the array.
   */
  const handleCategoryBulkEdit = async ({ newValue }) => {
    const firstItem = newValue?.[0];

    if (!firstItem) return;

    setCategoryAnchorEl(null);
    await bulkEditBase({
      type: 'keeper_category_id',
      value: firstItem
    });
  };

  const handleSplitAmountsMenuSelect = () => {
    trackActivity('expense review: bulk edit actions', { action: 'split amounts' });
    setExpenseReviewModalType(EXPENSE_REVIEW_MODAL_TYPES.BULK_SPLIT);
  };

  const handleNoteMenuSelect = () => {
    trackActivity('expense review: bulk edit actions', { action: 'note' });
    setExpenseReviewModalType(EXPENSE_REVIEW_MODAL_TYPES.BULK_NOTE);
  };

  if (!selectedRows.length && !loading) {
    return null;
  }

  return (
    <Toolbar
      sx={{
        backgroundColor: colorSurface,
        border: `1px solid ${colorNeutralMetallica}`,
        borderRadius: '8px 8px 0 0',
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        paddingVertical: '16px'
      }}
    >
      <div
        style={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'space-between',

          width: 'fit-content',
          minWidth: '768px'
        }}
      >
        <div
          style={{
            alignContent: 'center',
            borderRight: `1px solid ${colorNeutralMetallica}`,
            display: 'flex'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>{selectedRows?.length} selected</div>

          <Button variant='toolbar-button' onClick={() => setSelectedRows([])}>
            Clear
          </Button>
        </div>
        <div>
          <Button variant='toolbar-button' onClick={handleStatusMenuSelect}>
            <Tag size={14} color={colorSecondary} style={{ marginRight: '4px' }} />
            Status
          </Button>
          <Menu
            anchorEl={statusAnchorEl}
            open={Boolean(statusAnchorEl)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={() => setStatusAnchorEl(null)}
            PaperProps={{
              style: {
                borderRadius: '8px',
                padding: '0 4px'
              }
            }}
          >
            <MenuItem disabled>Select a status</MenuItem>
            <MenuItem onClick={() => handleStatusBulkEdit('yes')}>
              <IconCheckGreen style={{ marginRight: '8px' }} height={20} width={20} />
              Deduction
            </MenuItem>
            <MenuItem onClick={() => handleStatusBulkEdit('no')}>
              <IconClose style={{ marginRight: '8px' }} height={20} width={20} />
              Not deductible
            </MenuItem>
          </Menu>
        </div>
        <div>
          <Button variant='toolbar-button' onClick={handleCategoryMenuSelect}>
            <Shapes size={14} color={colorSecondary} style={{ marginRight: '4px' }} />
            Category
          </Button>
          <CategorySearch
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            value={[]}
            onChange={handleCategoryBulkEdit}
            handleClose={() => setCategoryAnchorEl(null)}
            filterOptions={categoryOptions}
          />
        </div>
        {/* TODO:(KEE-2608): Make bulk edit work with job split allocations  */}
        {!jobLevelExpenseSplitEnabled && !isFeatureFlagLoading && (
          <Button variant='toolbar-button' onClick={handleSplitAmountsMenuSelect}>
            <DollarCircle size={14} color={colorSecondary} style={{ marginRight: '4px' }} />
            Split amounts
          </Button>
        )}
        <Button variant='toolbar-button' onClick={handleNoteMenuSelect}>
          <Note size={14} color={colorSecondary} style={{ marginRight: '4px' }} />
          Note
        </Button>
      </div>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  categoryOptions: manualExpenseCategoryOptionsListSelector(state),
  loading: bulkEditLoadingSelector(state)
});

const mapDispatchToProps = {
  setExpenseReviewModalType,
  bulkEditBase
};

const ConnectedBulkEditToolbar = connect(mapStateToProps, mapDispatchToProps)(BulkEditToolbar);

export default ConnectedBulkEditToolbar;
