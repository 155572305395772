// path components
export const PATH_COMPONENT__SPECIAL_EARLY_EXIT = 'special-early-exit';
export const PATH_COMPONENT__FIND_WRITE_OFFS = 'find-write-offs';
export const PATH_COMPONENT__PREMIUM_START = 'premium-start';
export const PATH_COMPONENT__SPECIAL_CRYPTO = 'income-crypto';
export const PATH_COMPONENT__MATCHING = 'matching';
export const PATH_COMPONENT__MATCHING_COMPLETE = 'matching-complete';
export const PATH_COMPONENT__EXTENSION_START = 'extension-start';
export const PATH_COMPONENT__EXTENSION_CONFIRMATION = 'extension-confirmation';

export const PATH_COMPONENT__TAX_HOME = 'home';
export const PATH_COMPONENT__BULK_UPLOAD_QUESTIONS = 'bulk-upload-questions';
export const PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE = 'bulk-upload-multi-image';
export const PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE = 'bulk-upload-photo-capture';
export const PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY = 'bulk-upload-manual-entry';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_1099 = 'bulk-upload-has-1099';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_W2 = 'bulk-upload-has-w2';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_1099G = 'bulk-upload-has-1099g';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_INT = 'bulk-upload-has-int';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_DIV = 'bulk-upload-has-div';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST = 'bulk-upload-has-invest';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT = 'bulk-upload-has-retirement';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_W2G = 'bulk-upload-has-w2g';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_HSA = 'bulk-upload-has-hsa';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_IRA = 'bulk-upload-has-ira';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS = 'bulk-upload-has-student-loans';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE = 'bulk-upload-has-mortgage';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY = 'bulk-upload-has-energy';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING = 'bulk-upload-has-teaching';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION = 'bulk-upload-has-tuition';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY = 'bulk-upload-has-charity';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE = 'bulk-upload-has-childcare';
export const PATH_COMPONENT__INCOME_ADD_MORE = 'income-add-more';
export const PATH_COMPONENT__CREDIT_ADD_MORE = 'credit-add-more';

// slugs
export const SLUG__SPECIAL_EARLY_EXIT = 'special-early-exit';
export const SLUG__FIND_WRITE_OFFS = 'find-write-offs';
export const SLUG__PREMIUM_START = 'premium-start';
export const SLUG__MATCHING = 'matching';
export const SLUG__MATCHING_COMPLETE = 'matching-complete';
export const SLUG__SPECIAL_CRYPTO = 'special-crypto';
export const SLUG__EXTENSION_START = 'extension-start';
export const SLUG__EXTENSION_CONFIRMATION = 'extension-confirmation';

export const SLUG__BULK_UPLOAD_QUESTIONS = 'bulk-upload-questions';
export const SLUG__BULK_UPLOAD_MULTI_IMAGE = 'bulk-upload-multi-image';
export const SLUG__BULK_UPLOAD_PHOTO_CAPTURE = 'bulk-upload-photo-capture';
export const SLUG__BULK_UPLOAD_MANUAL_ENTRY = 'bulk-upload-manual-entry';
export const SLUG__BULK_UPLOAD_HAS_1099 = 'bulk-upload-has-1099';
export const SLUG__BULK_UPLOAD_HAS_W2 = 'bulk-upload-has-w2';
export const SLUG__BULK_UPLOAD_HAS_1099G = 'bulk-upload-has-1099g';
export const SLUG__BULK_UPLOAD_HAS_INT = 'bulk-upload-has-int';
export const SLUG__BULK_UPLOAD_HAS_DIV = 'bulk-upload-has-div';
export const SLUG__BULK_UPLOAD_HAS_INVEST = 'bulk-upload-has-invest';
export const SLUG__BULK_UPLOAD_HAS_RETIREMENT = 'bulk-upload-has-retirement';
export const SLUG__BULK_UPLOAD_HAS_W2G = 'bulk-upload-has-w2g';
export const SLUG__BULK_UPLOAD_HAS_HSA = 'bulk-upload-has-hsa';
export const SLUG__BULK_UPLOAD_HAS_IRA = 'bulk-upload-has-ira';
export const SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS = 'bulk-upload-has-student-loans';
export const SLUG__BULK_UPLOAD_HAS_MORTGAGE = 'bulk-upload-has-mortgage';
export const SLUG__BULK_UPLOAD_HAS_ENERGY = 'bulk-upload-has-energy';
export const SLUG__BULK_UPLOAD_HAS_TEACHING = 'bulk-upload-has-teaching';
export const SLUG__BULK_UPLOAD_HAS_TUITION = 'bulk-upload-has-tuition';
export const SLUG__BULK_UPLOAD_HAS_CHARITY = 'bulk-upload-has-charity';
export const SLUG__BULK_UPLOAD_HAS_CHILDCARE = 'bulk-upload-has-childcare';
export const SLUG__BULK_UPLOAD_HAS_QUARTERLY = 'bulk-upload-has-quarterly';
export const SLUG__BULK_UPLOAD_HAS_RENTAL = 'bulk-upload-has-rental';
export const SLUG__BULK_UPLOAD_HAS_CAPITAL_LOSS = 'bulk-upload-has-capital-loss';
export const SLUG__BULK_UPLOAD_CHARITY_OPTION = 'bulk-upload-charity-option';
export const SLUG__INCOME_ADD_MORE = 'income-add-more';
export const SLUG__CREDIT_ADD_MORE = 'credit-add-more';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__ALL_FORMS_UPLOADED = 'all-forms-uploaded';

// collection types
export const COLLECTION_TYPE__SPECIAL = 'special';
export const COLLECTION_TYPE__SUGGESTION = 'suggestion';

export const BULK_UPLOAD_FORM_PREDICT_QUESTIONS = [
  SLUG__BULK_UPLOAD_HAS_1099,
  SLUG__BULK_UPLOAD_HAS_W2,
  SLUG__BULK_UPLOAD_HAS_1099G,
  SLUG__BULK_UPLOAD_HAS_INT,
  SLUG__BULK_UPLOAD_HAS_DIV,
  SLUG__BULK_UPLOAD_HAS_INVEST,
  SLUG__BULK_UPLOAD_HAS_RETIREMENT,
  SLUG__BULK_UPLOAD_HAS_W2G,
  SLUG__BULK_UPLOAD_HAS_HSA,
  SLUG__BULK_UPLOAD_HAS_IRA,
  SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS,
  SLUG__BULK_UPLOAD_HAS_CHARITY,
  SLUG__BULK_UPLOAD_HAS_MORTGAGE,
  SLUG__BULK_UPLOAD_HAS_ENERGY,
  SLUG__BULK_UPLOAD_HAS_TEACHING,
  SLUG__BULK_UPLOAD_HAS_TUITION,
  SLUG__BULK_UPLOAD_HAS_CHILDCARE,
  SLUG__BULK_UPLOAD_HAS_QUARTERLY,
  SLUG__BULK_UPLOAD_HAS_RENTAL,
  SLUG__BULK_UPLOAD_HAS_CAPITAL_LOSS,
  SLUG__EXTENSION_START
];
