import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import { specificFeatureSelector, useGetFeaturesQuery } from '@app/src/api/profileApi';
import { useGenerateQuestionnaireMutation, useGetBulkUploadPillsQuery } from '@app/src/api/taxDataApi';
import { useUIState } from '@app/src/hooks/useUIState';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

/**
 * Re-generate the questionnaire when the user adds / removes forms in bulk upload, if the questionnaire is not yet started
 */
export const useRegenerateQuestionnaire = () => {
  const { pathname } = useLocation();
  const year = useSelector(yearSelector);
  const [isRegenerationQueued, setIsRegenerationQueued] = useState(false);
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery({ year });
  const { skipQuestionnaire, isLoading: isSkipQuestionnaireLoading } = useGetFeaturesQuery(undefined, {
    selectFromResult: (result) => {
      return {
        ...result,
        skipQuestionnaire: specificFeatureSelector(result, 'skip-questionnaire')
      };
    }
  });
  const { uiState, isLoading: isUIStateLoading } = useUIState({ taxYear: year });

  const [generateQuestionnaire] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const isTaxHome = pathname === `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`;

  const isParseInProgress = _.some(bulkUploadPills, { loading: true });

  useEffect(() => {
    if (
      isRegenerationQueued &&
      !isParseInProgress &&
      isTaxHome &&
      !skipQuestionnaire &&
      !isSkipQuestionnaireLoading &&
      // Only re-generate questionnaire if the questionnaire is accessible to the user
      _.includes(['bulk-started', 'questionnaire'], uiState?.slug) &&
      !isUIStateLoading
    ) {
      generateQuestionnaire({ year });
      setIsRegenerationQueued(false);
    }
  }, [
    bulkUploadPills,
    isParseInProgress,
    isTaxHome,
    isRegenerationQueued,
    year,
    skipQuestionnaire,
    isSkipQuestionnaireLoading,
    uiState?.slug,
    isUIStateLoading,
    generateQuestionnaire
  ]);

  useDeepCompareEffect(() => {
    // Don't regenerate on initial page load, but regenerate on subsequent bulk upload pills changes
    if (isInitialLoad) {
      setIsInitialLoad(false);
    } else {
      setIsRegenerationQueued(true);
    }
  }, [_.defaultTo(bulkUploadPills, [])]);
};
