import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import LogoAppIcon from '@app/src/assets/logo-app-icon.svg?react';
import { colorDeactivatedLight } from '@app/src/theme';
import '@app/src/Components/Common/NotificationExample/NotificationExample.scss';

const NotificationExample = ({ text, time, numOfLines, style }) => (
  <div className='notification-container' style={style}>
    <div className='content-left'>
      <LogoAppIcon style={{ transform: 'scale(1.1)' }} />
    </div>
    <div className='content-right'>
      <div className='text-container'>
        <div className='copy'>{text}</div>
        <div className='time'>{time}</div>
      </div>
      {Array.from({ length: numOfLines }, (_, i) => (
        <Skeleton
          key={i}
          animation={false}
          height={14}
          sx={{ bgcolor: colorDeactivatedLight, marginBottom: '8px', borderRadius: '4px' }}
          variant='rectangular'
          width={`calc(100% - ${i * 10}%)`}
        />
      ))}
    </div>
  </div>
);

export default NotificationExample;
