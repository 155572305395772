import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import moment from 'moment';
import { useForm, Controller, useWatch, useFormState } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import InformationBanner from '@app/src/Components/Common/InformationBanner/InformationBanner';
import KeeperSlider from '@app/src/Components/Common/KeeperSlider/KeeperSlider';
import LinkedLockoutInput from '@app/src/Components/Common/LinkedLockoutInput/LinkedLockoutInput';
import ExpenseReviewCategoryDescription from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryDescription';
import ExpenseReviewCategoryDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryDropdown';
import ExpenseReviewEditPanelDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewEditPanelDropdown';
import useGetOrCreateTaxProfileQuery from '@app/src/Components/TaxProfile/hooks/useGetOrCreateTaxProfileQuery';
import { useGetTaxProfileData } from '@app/src/Components/TaxProfile/hooks/useTaxProfile';
import {
  setSelectedExpense,
  setExpenseReviewModalType,
  setSidebarComponent
} from '@app/src/actions/expenseReviewActions';
import { useGetFeaturesQuery, specificFeatureSelector } from '@app/src/api/profileApi';
import { EXPENSE_REVIEW_STATUS_OPTIONS_LIST, OTHER_CATEGORY_ID } from '@app/src/constants/constants';
import { numberWith0DecimalPlaces, currencyWith2DecimalPlaces } from '@app/src/global/Helpers';
import useIsInConfirmAmounts from '@app/src/hooks/useIsInConfirmAmounts';
import {
  categoryIdsToDisplayNamesSelector,
  displayedDescriptionSelector,
  hideStatusEditModalSelector,
  isModalSubmittingSelector,
  loadingSelector,
  notDeductibleIdsSelector,
  recategorizeOptionsListSelector,
  selectedExpenseAllDataSelector,
  selectedExpenseSelector,
  allCategoriesSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { infoModalItemSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { isExpensesYearEqualToCurrentTaxYearSelector } from '@app/src/selectors/taxFlowSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { deleteManualExpense, editExpenseInternal } from '@app/src/services/expenseReviewService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { determineExpenseSplitType } from '@app/src/utils/commonUtils';
import '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit.scss';

const calculateBusinessUsePercentageFromJobPercentages = (jobPercentages) => {
  if (!jobPercentages) return 100;
  return jobPercentages.reduce((sum, { splitPercent }) => sum + Number(splitPercent), 0);
};

// RHF (React Hook Form) treats numeric keys as array indices, which would create huge sparse arrays
// for IDs like 623000 in which case the form would be 623000 elements long. Even coercing to string
// doesn't help, so we prefix with 'job_' to force RHF to treat it as an object key
const getJobKey = (job) => `job_${job.id}`;

const LinkedLockoutBusinessUseSection = ({ categoryUsagePercent, lockoutCategoryInfo, navigateToTaxProfile }) => {
  return (
    <>
      <LinkedLockoutInput
        label='For personal use'
        value={`${100 - categoryUsagePercent}%`}
        lightExplainerText={`, default for ${lockoutCategoryInfo.label}`}
        onClick={navigateToTaxProfile}
        analyticsType={lockoutCategoryInfo.label}
      />
      <LinkedLockoutInput
        label='For business / freelancing'
        value={`${categoryUsagePercent}%`}
        lightExplainerText={`, default for ${lockoutCategoryInfo.label}`}
        onClick={navigateToTaxProfile}
        analyticsType={lockoutCategoryInfo.label}
      />
    </>
  );
};

const StandardBusinessUsePercentageSection = ({
  businessUsePercentage,
  lockoutCategoryInfo,
  categoryUsagePercent,
  setValue,
  isModalSubmitting,
  navigateToTaxProfile
}) => {
  if (lockoutCategoryInfo) {
    return (
      <LinkedLockoutBusinessUseSection
        categoryUsagePercent={categoryUsagePercent}
        lockoutCategoryInfo={lockoutCategoryInfo}
        navigateToTaxProfile={navigateToTaxProfile}
      />
    );
  }

  return (
    <>
      <KeeperSlider
        label='For personal use'
        value={100 - businessUsePercentage}
        onSliderChange={(value) => setValue('businessUsePercentage', 100 - value, { shouldDirty: true })}
        onInputChange={(value) => setValue('businessUsePercentage', 100 - Number(value), { shouldDirty: true })}
        showInput
        disabled={isModalSubmitting}
        format='###%'
      />
      <KeeperSlider
        label='For business / freelancing'
        value={numberWith0DecimalPlaces(businessUsePercentage)}
        onSliderChange={(value) => setValue('businessUsePercentage', value, { shouldDirty: true })}
        onInputChange={(value) => setValue('businessUsePercentage', Number(value), { shouldDirty: true })}
        showInput
        disabled={isModalSubmitting}
        format='###%'
      />
    </>
  );
};

const JobPercentageSlider = ({ job, control, isSpouseJob, hasSpouseJobs, isModalSubmitting, allocationDifference }) => {
  let jobLabel = job.name;

  if (isSpouseJob) {
    jobLabel += ' (Spouse)';
  } else if (hasSpouseJobs) {
    jobLabel += ' (Me)';
  }

  return (
    <Controller
      key={job.id}
      name={`jobPercentages.${getJobKey(job)}`}
      control={control}
      render={({ field }) => (
        <KeeperSlider
          label={`For ${_.lowerFirst(jobLabel)}`}
          value={numberWith0DecimalPlaces(field.value)}
          onSliderChange={(value) => field.onChange(value, { shouldDirty: true })}
          onInputChange={(value) => field.onChange(Number(value), { shouldDirty: true })}
          showInput
          disabled={isModalSubmitting}
          format='###%'
          isError={allocationDifference !== 0 && !isModalSubmitting}
        />
      )}
    />
  );
};

const JobLevelOverwriteSmartSplit = ({
  businessUsePercentage,
  lockoutCategoryInfo,
  categoryUsagePercent,
  control,
  setValue,
  isModalSubmitting,
  navigateToTaxProfile,
  taxProfileData,
  allocationDifference
}) => {
  const hasSpouseJobs = taxProfileData?.spouseJobs?.length > 0;

  return (
    <>
      {lockoutCategoryInfo ? (
        <LinkedLockoutInput
          label='For personal use'
          value={`${100 - categoryUsagePercent}%`}
          lightExplainerText={`, default for ${lockoutCategoryInfo.label}`}
          onClick={navigateToTaxProfile}
          analyticsType={lockoutCategoryInfo.label}
          navigateToTaxProfile={navigateToTaxProfile}
        />
      ) : (
        <Controller
          name='personalUsePercentage'
          control={control}
          render={({ field }) => (
            <KeeperSlider
              label='For personal use'
              value={100 - businessUsePercentage}
              onSliderChange={(value) => {
                field.onChange(value);
                setValue('businessUsePercentage', 100 - value, { shouldDirty: true });
              }}
              onInputChange={(value) => {
                const numValue = Number(value);
                field.onChange(numValue);
                setValue('businessUsePercentage', 100 - numValue, { shouldDirty: true });
              }}
              showInput
              disabled={isModalSubmitting}
              format='###%'
              isError={allocationDifference !== 0 && !isModalSubmitting}
            />
          )}
        />
      )}

      {taxProfileData?.jobs?.map((job) => {
        if (!job) return null;
        return (
          <JobPercentageSlider
            key={job.id}
            job={job}
            control={control}
            isSpouseJob={false}
            hasSpouseJobs={hasSpouseJobs}
            isModalSubmitting={isModalSubmitting}
            allocationDifference={allocationDifference}
          />
        );
      })}

      {taxProfileData?.spouseJobs?.map((job) => {
        if (!job) return null;
        return (
          <JobPercentageSlider
            key={job.id}
            job={job}
            control={control}
            isSpouseJob={true}
            hasSpouseJobs={hasSpouseJobs}
            isModalSubmitting={isModalSubmitting}
            allocationDifference={allocationDifference}
          />
        );
      })}

      {allocationDifference !== 0 && !isModalSubmitting && (
        <div className='error-message'>
          {allocationDifference > 0
            ? `You've over-allocated by ${Math.abs(allocationDifference)}%`
            : `You've under-allocated by ${Math.abs(allocationDifference)}%`}
        </div>
      )}

      <Button fullWidth onClick={navigateToTaxProfile}>
        Manage work types
      </Button>
    </>
  );
};

const JobLevelSmartSplitView = ({
  businessUsePercentage,
  lockoutCategoryInfo,
  categoryUsagePercent,
  setValue,
  isModalSubmitting,
  navigateToTaxProfile,
  taxProfileData,
  setIsOverwritingSmartSplit,
  distributeWholeNumberPercentages,
  defaultJobPercentages
}) => {
  const numTotalJobs = (taxProfileData?.jobs?.length || 0) + (taxProfileData?.spouseJobs?.length || 0);
  const handleSetSmartSplit = () => {
    setIsOverwritingSmartSplit(true);

    // Only proceed with setting values if defaultValues.jobPercentages is empty
    const isDefaultJobPercentagesEmpty = Object.keys(defaultJobPercentages).length === 0;
    if (isDefaultJobPercentagesEmpty) {
      const jobs = taxProfileData?.jobs || [];
      const spouseJobs = taxProfileData?.spouseJobs || [];

      if (numTotalJobs > 0) {
        const allocations = distributeWholeNumberPercentages(businessUsePercentage, numTotalJobs);

        const newJobPercentages = {
          ...jobs.reduce(
            (acc, job, index) => ({
              ...acc,
              [getJobKey(job)]: allocations[index]
            }),
            {}
          ),

          ...spouseJobs.reduce(
            (acc, job, index) => ({
              ...acc,
              [getJobKey(job)]: allocations[jobs.length + index]
            }),
            {}
          )
        };

        setValue('jobPercentages', newJobPercentages, { shouldDirty: false });
      }
    }

    trackActivity('expense quickview: action', {
      action: 'overwrite smart split'
    });
  };

  return (
    <>
      <StandardBusinessUsePercentageSection
        businessUsePercentage={businessUsePercentage}
        lockoutCategoryInfo={lockoutCategoryInfo}
        categoryUsagePercent={categoryUsagePercent}
        setValue={setValue}
        isModalSubmitting={isModalSubmitting}
        navigateToTaxProfile={navigateToTaxProfile}
      />

      {numTotalJobs > 1 && (
        <InformationBanner
          text='Smart-split™ is an accounting technique where your expense is split across your freelance / business jobs according to reported income and industry benchmarks.'
          buttonText='Overwrite smart-split™'
          onButtonClick={handleSetSmartSplit}
        />
      )}
    </>
  );
};

const JobLevelBusinessUsePercentageSection = ({
  businessUsePercentage,
  lockoutCategoryInfo,
  categoryUsagePercent,
  control,
  setValue,
  isModalSubmitting,
  navigateToTaxProfile,
  taxProfileData,
  isOverwritingSmartSplit,
  setIsOverwritingSmartSplit,
  distributeWholeNumberPercentages,
  allocationDifference,
  defaultJobPercentages
}) => {
  if (isOverwritingSmartSplit) {
    return (
      <JobLevelOverwriteSmartSplit
        businessUsePercentage={businessUsePercentage}
        lockoutCategoryInfo={lockoutCategoryInfo}
        categoryUsagePercent={categoryUsagePercent}
        control={control}
        setValue={setValue}
        isModalSubmitting={isModalSubmitting}
        navigateToTaxProfile={navigateToTaxProfile}
        taxProfileData={taxProfileData}
        allocationDifference={allocationDifference}
      />
    );
  }

  return (
    <JobLevelSmartSplitView
      businessUsePercentage={businessUsePercentage}
      lockoutCategoryInfo={lockoutCategoryInfo}
      categoryUsagePercent={categoryUsagePercent}
      setValue={setValue}
      isModalSubmitting={isModalSubmitting}
      navigateToTaxProfile={navigateToTaxProfile}
      taxProfileData={taxProfileData}
      setIsOverwritingSmartSplit={setIsOverwritingSmartSplit}
      distributeWholeNumberPercentages={distributeWholeNumberPercentages}
      defaultJobPercentages={defaultJobPercentages}
    />
  );
};

const BusinessUsePercentageSection = ({
  businessUsePercentage,
  isDeductibleBusinessExpense,
  lockoutCategoryInfo,
  categoryUsagePercent,
  control,
  setValue,
  isModalSubmitting,
  setSidebarComponent,
  setExpenseReviewModalType,
  jobLevelExpenseSplitEnabled,
  isFeatureFlagLoading,
  allocationDifference,
  isOverwritingSmartSplit,
  setIsOverwritingSmartSplit,
  defaultJobPercentages,
  taxProfileData
}) => {
  const distributeWholeNumberPercentages = useCallback((totalPercentage, itemCount) => {
    if (itemCount === 0) return [];

    return Array(itemCount)
      .fill(0)
      .map((_, index) => {
        const base = Math.floor(totalPercentage / itemCount);
        const additional = index < totalPercentage % itemCount ? 1 : 0;
        return base + additional;
      });
  }, []);

  const navigateToTaxProfile = useCallback(() => {
    trackActivity('expense quickview: action', { action: 'navigate to tax profile' });
    setExpenseReviewModalType(null);
    setSidebarComponent('TaxProfileForm', { initialSection: 'CategoryUsage' });
    setIsOverwritingSmartSplit(false);
  }, [setExpenseReviewModalType, setIsOverwritingSmartSplit, setSidebarComponent]);

  if (!isDeductibleBusinessExpense || isFeatureFlagLoading) return null;

  if (jobLevelExpenseSplitEnabled) {
    return (
      <JobLevelBusinessUsePercentageSection
        businessUsePercentage={businessUsePercentage}
        lockoutCategoryInfo={lockoutCategoryInfo}
        categoryUsagePercent={categoryUsagePercent}
        control={control}
        setValue={setValue}
        isModalSubmitting={isModalSubmitting}
        navigateToTaxProfile={navigateToTaxProfile}
        taxProfileData={taxProfileData}
        isOverwritingSmartSplit={isOverwritingSmartSplit}
        setIsOverwritingSmartSplit={setIsOverwritingSmartSplit}
        distributeWholeNumberPercentages={distributeWholeNumberPercentages}
        allocationDifference={allocationDifference}
        defaultJobPercentages={defaultJobPercentages}
      />
    );
  }

  return (
    <StandardBusinessUsePercentageSection
      businessUsePercentage={businessUsePercentage}
      lockoutCategoryInfo={lockoutCategoryInfo}
      categoryUsagePercent={categoryUsagePercent}
      setValue={setValue}
      isModalSubmitting={isModalSubmitting}
      navigateToTaxProfile={navigateToTaxProfile}
    />
  );
};

const ExpenseReviewModalEdit = ({
  selectedExpense,
  className,
  categoryOptionsList,
  categoryIdsToDisplayNames,
  allCategories,
  selectedExpenseAllData,
  displayedDescription,
  notDeductibleIds,
  setSelectedExpense,
  isModalSubmitting,
  setSelfUnlockModalOpen,
  deleteManualExpense,
  setExpenseReviewModalType,
  setSidebarComponent,
  loading,
  editExpenseInternal
}) => {
  const [isOverwritingSmartSplit, setIsOverwritingSmartSplit] = useState(() => {
    return !_.isEmpty(selectedExpense?.data?.jobPercentages);
  });
  const getOrCreateTaxProfileQueryResult = useGetOrCreateTaxProfileQuery();

  const { jobLevelExpenseSplitEnabled, isLoading: isFeatureFlagLoading } = useGetFeaturesQuery(null, {
    selectFromResult: (result) => {
      return {
        ...result,
        jobLevelExpenseSplitEnabled: specificFeatureSelector(result, 'job-level-expense-split')
      };
    }
  });

  const totalBusinessUsePercentage =
    calculateBusinessUsePercentageFromJobPercentages(selectedExpense?.data?.jobPercentages) ?? 0;

  // Cleanup only after successful save and unmount
  useEffect(() => {
    return () => {
      setSelectedExpense(null);
      setExpenseReviewModalType(null);
    };
  }, [setSelectedExpense, setExpenseReviewModalType]);

  const defaultValues = useMemo(() => {
    const existingJobPercentages = {};

    const jobPercentagesData = selectedExpense?.data?.jobPercentages;
    if (Array.isArray(jobPercentagesData)) {
      jobPercentagesData.forEach(({ jobId, splitPercent }) => {
        const key = getJobKey({ id: jobId });
        existingJobPercentages[key] = Number(splitPercent);
      });
    }

    // If there are any job percentages, ensure every job has a defined percentage
    if (Object.keys(existingJobPercentages).length > 0) {
      const taxProfileData = getOrCreateTaxProfileQueryResult?.data;
      const jobs = taxProfileData?.jobs ?? [];
      const spouseJobs = taxProfileData?.spouseJobs ?? [];
      const allJobs = [...jobs, ...spouseJobs];

      allJobs.forEach((job) => {
        if (job) {
          const key = getJobKey(job);
          if (!(key in existingJobPercentages)) {
            existingJobPercentages[key] = 0;
          }
        }
      });
    }

    return {
      status: selectedExpense?.data?.status ?? '',
      keeper_category_id: selectedExpense?.data?.keeper_category_id ?? '',
      businessUsePercentage: jobLevelExpenseSplitEnabled
        ? totalBusinessUsePercentage
        : selectedExpense?.data?.businessUsePercentage,
      note: selectedExpense?.data?.note ?? '',
      jobPercentages: existingJobPercentages
    };
  }, [selectedExpense, getOrCreateTaxProfileQueryResult, jobLevelExpenseSplitEnabled, totalBusinessUsePercentage]);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues
  });
  const { dirtyFields } = useFormState({ control });

  const currentStatus = watch('status');
  const watchedCategoryId = watch('keeper_category_id');

  const currentCategory = useMemo(
    () => allCategories.find((category) => category.id === watchedCategoryId),
    [allCategories, watchedCategoryId]
  );

  const lockoutCategoryInfo = useMemo(() => determineExpenseSplitType(currentCategory), [currentCategory]);

  const categoryUsagePercent = useMemo(() => {
    if (!getOrCreateTaxProfileQueryResult.data || !lockoutCategoryInfo) {
      return 0;
    }
    return getOrCreateTaxProfileQueryResult.data?.[lockoutCategoryInfo.percentageField] ?? 0;
  }, [getOrCreateTaxProfileQueryResult.data, lockoutCategoryInfo]);

  useEffect(() => {
    if (lockoutCategoryInfo && categoryUsagePercent > 0) {
      setValue('businessUsePercentage', categoryUsagePercent);
    } else if (jobLevelExpenseSplitEnabled && selectedExpense?.data?.jobPercentages?.length > 0) {
      const totalBusinessUsePercentage = calculateBusinessUsePercentageFromJobPercentages(
        selectedExpense?.data?.jobPercentages
      );
      setValue('businessUsePercentage', totalBusinessUsePercentage);
    } else if (selectedExpense?.data?.businessUsePercentage != null) {
      setValue('businessUsePercentage', selectedExpense.data.businessUsePercentage);
    } else if (currentStatus === 'yes') {
      setValue('businessUsePercentage', 100);
    }
  }, [
    watchedCategoryId,
    lockoutCategoryInfo,
    categoryUsagePercent,
    currentStatus,
    selectedExpense,
    setValue,
    jobLevelExpenseSplitEnabled
  ]);

  const businessUsePercentage = watch('businessUsePercentage');

  const accountName = useMemo(() => _.get(selectedExpenseAllData, 'bank_acct_name'), [selectedExpenseAllData]);

  const { data: isInConfirmAmounts, isLoading: isInConfirmAmountsLoading } = useIsInConfirmAmounts();
  const isExpensesYearEqualToCurrentTaxYear = useSelector(isExpensesYearEqualToCurrentTaxYearSelector);
  const { data: taxProfileData } = useGetTaxProfileData();

  const jobPercentages = useWatch({
    control,
    name: 'jobPercentages',
    defaultValue: defaultValues.jobPercentages
  });

  const totalJobAllocation = useMemo(() => {
    if (!isOverwritingSmartSplit) return businessUsePercentage;

    const percentages = Object.keys(jobPercentages).length === 0 ? defaultValues.jobPercentages : jobPercentages;

    const total = Object.values(percentages || {}).reduce((sum, value) => {
      return sum + (Number(value) || 0);
    }, 0);

    return total;
  }, [isOverwritingSmartSplit, businessUsePercentage, jobPercentages, defaultValues.jobPercentages]);

  const allocationDifference = useMemo(() => {
    if (!isOverwritingSmartSplit) return 0;

    const difference = Math.round(totalJobAllocation) - Math.round(businessUsePercentage);
    return difference;
  }, [isOverwritingSmartSplit, totalJobAllocation, businessUsePercentage]);

  const isInvalidDeduction = useMemo(
    () => currentStatus === 'yes' && (businessUsePercentage === 0 || businessUsePercentage === null),
    [currentStatus, businessUsePercentage]
  );

  const handleSave = async (formData) => {
    if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
      setSelfUnlockModalOpen(true);
      return;
    }

    const hasModifiedJobPercentages = dirtyFields.jobPercentages && !_.isEmpty(formData.jobPercentages);

    let jobPercentages = [];

    if (hasModifiedJobPercentages) {
      jobPercentages = Object.entries(formData.jobPercentages).reduce((acc, [key, value]) => {
        const split = Number(value);
        // Only include if split is a valid number and greater than 0
        if (!Number.isNaN(split) && split > 0) {
          acc.push({
            jobId: parseInt(key.replace('job_', ''), 10),
            splitPercent: split
          });
        }
        return acc;
      }, []);
    }

    const processedData = {
      ...selectedExpenseAllData,
      ...formData,
      jobPercentages
    };

    setSelectedExpense({
      transactionId: selectedExpense.transactionId,
      data: processedData
    });

    try {
      await editExpenseInternal(processedData, jobLevelExpenseSplitEnabled);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const isDeductibleBusinessExpense =
    currentStatus === 'yes' && currentCategory?.transaction_type === 'possible_business_deduction';

  if (isInConfirmAmountsLoading) {
    return null;
  }

  return (
    <div className={classNames('expense-review-modal-edit', className)}>
      <div className='expense-review-modal-edit-merchant'>
        {selectedExpense?.data?.amount
          ? `${selectedExpenseAllData.clean_name} (${currencyWith2DecimalPlaces(selectedExpense?.data?.amount)})`
          : selectedExpenseAllData?.clean_name ?? ''}
      </div>
      <div className='expense-review-modal-edit-line-spacer' />
      {accountName !== 'manually added' && !_.includes(accountName, 'expense upload') ? (
        <div style={{ marginBottom: '16px' }}>
          {`Originally appeared as ${_.get(selectedExpenseAllData, 'name')} on your bank statement on ${moment(
            _.get(selectedExpenseAllData, 'date', ''),
            'YYYY-MM-DD'
          ).format('M/D/YYYY')}.`}
        </div>
      ) : (
        <div style={{ marginBottom: '16px' }}>
          {`Expense manually added. Purchased on ${moment(
            _.get(selectedExpenseAllData, 'date', ''),
            'YYYY-MM-DD'
          ).format('M/D/YYYY')}.`}
        </div>
      )}

      {displayedDescription && (
        <ExpenseReviewCategoryDescription description={displayedDescription} expense={selectedExpenseAllData} />
      )}
      <form onSubmit={handleSubmit(handleSave)}>
        <Stack spacing={1}>
          <div>
            <InputLabel>Status</InputLabel>
            <Controller
              name='status'
              control={control}
              rules={{ required: 'Status is required' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <ExpenseReviewEditPanelDropdown
                    {...field}
                    labelId='edit-dropdown-status'
                    disabled={isModalSubmitting}
                    displayName={
                      EXPENSE_REVIEW_STATUS_OPTIONS_LIST.find((option) => option.value === field.value)?.displayName ||
                      'Select status'
                    }
                    options={EXPENSE_REVIEW_STATUS_OPTIONS_LIST}
                    error={!!error}
                  />
                  {error && <span className='error-message'>{error.message}</span>}
                </>
              )}
            />
          </div>
          <div>
            <InputLabel id='edit-dropdown-category'>Category</InputLabel>
            <Controller
              name='keeper_category_id'
              control={control}
              rules={{ required: 'Category is required' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <ExpenseReviewCategoryDropdown
                    {...field}
                    disabled={isModalSubmitting}
                    options={categoryOptionsList}
                    displayName={categoryIdsToDisplayNames[field.value] || 'Select category'}
                    onChange={(value, inputValue) => {
                      const categoryId = _.get(value, ['value']);
                      if (_.isNil(categoryId)) return;

                      field.onChange(categoryId);

                      if (_.includes(notDeductibleIds, categoryId)) {
                        setValue('status', 'no');
                      }

                      if (categoryId === OTHER_CATEGORY_ID && inputValue) {
                        trackActivity('recategorize to other', { query: inputValue });
                      }
                    }}
                    error={!!error}
                  />
                  {error && <span className='error-message'>{error.message}</span>}
                </>
              )}
            />
          </div>
          <BusinessUsePercentageSection
            businessUsePercentage={businessUsePercentage}
            isDeductibleBusinessExpense={isDeductibleBusinessExpense}
            lockoutCategoryInfo={lockoutCategoryInfo}
            categoryUsagePercent={categoryUsagePercent}
            control={control}
            setValue={setValue}
            isModalSubmitting={isModalSubmitting}
            setSidebarComponent={setSidebarComponent}
            setExpenseReviewModalType={setExpenseReviewModalType}
            jobLevelExpenseSplitEnabled={jobLevelExpenseSplitEnabled}
            isFeatureFlagLoading={isFeatureFlagLoading}
            allocationDifference={allocationDifference}
            isOverwritingSmartSplit={isOverwritingSmartSplit}
            setIsOverwritingSmartSplit={setIsOverwritingSmartSplit}
            defaultJobPercentages={defaultValues.jobPercentages}
            taxProfileData={taxProfileData}
          />
          <div>
            <InputLabel id='edit-input-note'>Note (optional)</InputLabel>
            <Controller
              name='note'
              control={control}
              render={({ field }) => <TextField {...field} placeholder='e.g. Meeting with my client Zach' fullWidth />}
            />
          </div>
          {isInvalidDeduction && <div className='error-message'>A deduction cannot be 100% for personal use.</div>}
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            onClick={handleSubmit(handleSave)}
            disabled={(allocationDifference !== 0 && !isModalSubmitting) || isInvalidDeduction}
          >
            Save
          </Button>
          {(accountName === 'manually added' || _.includes(accountName, 'expense upload')) && (
            <Button
              fullWidth
              disabled={loading}
              onClick={deleteManualExpense}
              sx={{ color: 'danger.main' }}
              style={{ marginTop: '16px' }}
            >
              Delete
            </Button>
          )}
        </Stack>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  infoModalItem: infoModalItemSelector(state),
  selectedExpense: selectedExpenseSelector(state),
  categoryOptionsList: recategorizeOptionsListSelector(state),
  categoryIdsToDisplayNames: categoryIdsToDisplayNamesSelector(state),
  selectedExpenseAllData: selectedExpenseAllDataSelector(state),
  isModalSubmitting: isModalSubmittingSelector(state),
  hideStatus: hideStatusEditModalSelector(state),
  displayedDescription: displayedDescriptionSelector(state),
  notDeductibleIds: notDeductibleIdsSelector(state),
  loading: loadingSelector(state),
  workDetails: workDetailsSelector(state),
  allCategories: allCategoriesSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  deleteManualExpense: () => dispatch(deleteManualExpense()),
  setSelectedExpense: (data) => dispatch(setSelectedExpense(data)),
  setSelfUnlockModalOpen: (data) => dispatch(setSelfUnlockModalOpen(data)),
  setExpenseReviewModalType: (data) => dispatch(setExpenseReviewModalType(data)),
  setSidebarComponent: (component, props) => dispatch(setSidebarComponent(component, props)),
  editExpenseInternal: (formData, jobLevelExpenseSplitEnabled) =>
    dispatch(editExpenseInternal(formData, jobLevelExpenseSplitEnabled))
});

const ConnectedExpenseReviewModalEdit = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalEdit);

export default ConnectedExpenseReviewModalEdit;
