import React from 'react';
import url from 'url';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { useUpdateTaxDataMutation } from '@app/src/api/taxDataApi';
import CloseIcon from '@app/src/assets/close.svg?react';
import { useSubstitutedText } from '@app/src/hooks/useSubstitutedText';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { COLLECTION_TYPE__SUGGESTION } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/taxflow/collection/components/TaxFlowSuggestionModal.scss';

const TaxFlowSuggestionModal = ({
  title,
  header,
  suggestionModalText,
  shouldUnfurlBulkUpload,
  linkedSlug,
  linkedQuestionCollectionId,
  dismissSlug,
  onClose = _.noop,
  unfurlBulkUpload = _.noop
}) => {
  const { substitutedText: bodyText, isLoading } = useSubstitutedText({ text: suggestionModalText });
  const [updateTaxData] = useUpdateTaxDataMutation();
  const year = useSelector(yearSelector);

  if (isLoading) {
    return null;
  }

  const addSuggestion = () => {
    if (shouldUnfurlBulkUpload) {
      unfurlBulkUpload();
    } else if (linkedSlug) {
      const nextPathComponent = getPathComponentBySlug(linkedSlug);
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: linkedQuestionCollectionId === '0' ? undefined : linkedQuestionCollectionId
          }
        })
      );
    }
    trackActivity('suggestion pill: added', { pillName: title });
    removeSuggestion();
    onClose();
  };

  const skipSuggestion = () => {
    trackActivity('suggestion pill: skipped', { pillName: title });
    removeSuggestion();
    onClose();
  };

  const removeSuggestion = () => {
    updateTaxData({
      taxData: [
        {
          coll_id: DEFAULT_COLLECTION_ID,
          coll_type: COLLECTION_TYPE__SUGGESTION,
          slug: `${dismissSlug}-dismissed`,
          value: '1'
        }
      ],
      generateSharedCollectionId: false,
      year
    });
  };

  return (
    <Dialog open={true} onClose={onClose} className='suggestion-modal'>
      <div>
        <DialogTitle className='suggestion-modal-title'>
          {header}
          <CloseIcon onClick={onClose} className='taxflow-feedback-modal-header-icon' />
        </DialogTitle>
      </div>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions className='suggestion-modal-actions'>
        <Button variant='contained' color='secondary' onClick={addSuggestion} sx={{ margin: '16px' }}>
          Add
        </Button>
        <Button variant='outlined' onClick={skipSuggestion} sx={{ margin: '16px' }}>
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaxFlowSuggestionModal;
