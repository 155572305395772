import React, { forwardRef } from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxFlowFormIntItem = ({
  question,
  answer,
  resultLoading,
  hasError,
  onChange,
  onFocus = _.noop,
  onBlur = _.noop
}) => {
  const handleChange = ({ value }) => {
    onChange({
      slug: _.get(question, 'slug'),
      value,
      endpoint_attr: _.get(question, 'endpoint_attr')
    });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  if (resultLoading) {
    return null;
  }

  return (
    <div className='form-group'>
      <TaxValidationInputLabel question={question} />
      <div className='tax-flow-input-wrap'>
        <TextField
          fullWidth
          value={_.get(answer, 'value')}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={hasError}
          id={question.slug}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              question,
              answer
            }
          }}
        />
        <TaxFlowInfoButton currentQuestion={question} />
      </div>
      <TaxValidationWarnings question={question} answer={answer} />
    </div>
  );
};

const NumberFormatCustom = forwardRef(({ question, answer, onChange, ...props }, ref) => {
  const max = _.get(question, ['question_meta', 'max']);
  const allowLeadingZeros = _.get(question, ['question_meta', 'allowLeadingZeros']);

  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          value: values.value
        });
      }}
      inputMode='numeric'
      id={question.slug}
      value={_.get(answer, 'value')}
      decimalScale={0}
      placeholder={_.get(question, ['question_meta', 'default'])}
      maxLength={_.get(question, ['question_meta', 'maxLength'])}
      allowLeadingZeros={allowLeadingZeros}
      isAllowed={({ floatValue }) => {
        if (max) {
          return !floatValue || floatValue <= max;
        }

        return true;
      }}
    />
  );
});

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxFlowFormIntItem = connect(mapStateToProps)(TaxFlowFormIntItem);

export default ConnectedTaxFlowFormIntItem;
