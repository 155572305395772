import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ICON_URLS } from '@app/src/constants/constants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { setBlockingModalProps } from '@app/src/taxflow/navigation/actions/navigationActions';

/** Returns a function surfacing a modal communicating that a user either needs premium or that the current situation is supported by their premium plan */
export const useShowPremiumBlockingModal = () => {
  const dispatch = useDispatch();
  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();

  const showPremiumModal = useCallback(
    ({ formName, onSubmit, premiumCriteriaText, type, collectionTypeItem = null, submitButtonText }) => {
      if (isPremiumSubscriberLoading) {
        return null;
      }
      // Shared body for web and mobile
      const premiumBody = isPremiumSubscriber
        ? `A dedicated tax professional will email you directly, review all your information, and finalize your return before sending it to the IRS. They'll address any complex tax situations regarding ${formName}.`
        : `Keeper's premium plan allows you to file your taxes with hundreds of complex situations and upload any documents you want to share. After upgrading, a dedicated tax professional will email you directly, review all your information, and finalize your return before sending it to the IRS.\n\nYou'll be asked to upgrade to premium before the Keeper team reviews your return.`;

      // Formatted premium criteria text for web
      const formattedPremiumCriteriaText = premiumCriteriaText && (
        <>
          <strong>{premiumCriteriaText}</strong>
          <br />
          <br />
        </>
      );

      // Text for web
      const premiumModalTitle = isPremiumSubscriber
        ? `Your premium plan covers ${formName}`
        : `Premium plan required for ${formName}`;
      const premiumModalBody = isPremiumSubscriber ? (
        <>
          {formattedPremiumCriteriaText}
          {premiumBody}
        </>
      ) : (
        <>
          {formattedPremiumCriteriaText}
          {premiumBody}
        </>
      );

      // Text for mobile
      const premiumOverlayTitle = premiumModalTitle;
      const premiumOverlayBody = premiumBody;

      if (isReactNative()) {
        sentMsgToReactNative('open_taxflow_premium_blocking_overlay', {
          title: premiumOverlayTitle,
          premiumCriteriaText,
          body: premiumOverlayBody,
          headerIconUrl: ICON_URLS.MAGIC_STAR,
          type,
          collectionTypeItem,
          submitButtonText
        });
      } else {
        dispatch(
          setBlockingModalProps({
            open: true,
            slug: `${formName}-blocking-modal`,
            title: premiumModalTitle,
            body: premiumModalBody,
            iconUrl: ICON_URLS.MAGIC_STAR,
            onSubmit,
            submitButtonText
          })
        );
      }
    },
    [isPremiumSubscriber, isPremiumSubscriberLoading, dispatch]
  );

  return showPremiumModal;
};
