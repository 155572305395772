import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import BankLinkPlaid from '@app/src/Components/BankLinkPlaid/BankLinkPlaid';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxFlowResponseText from '@app/src/Components/TaxFlow/Common/TaxFlowResponseText';
import TaxFlowFormCustomJobItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormCustomJobItem';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { TaxFlowMiniDashboardPills } from '@app/src/Components/TaxFlow/Question/TaxFlowMiniDashboardPills';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { setContextLoading, setBusinessCode } from '@app/src/actions/taxFlowActions';
import { useGetHomeAddressPreselectOptionsQuery, useGetTaxDataQuery, useGetJobsQuery } from '@app/src/api/taxDataApi';
import BusinessCenterIcon from '@app/src/assets/briefcase.svg?react';
import { getCurrentYear, getPreviousYear, isReactNative, replaceString } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { initPlaid } from '@app/src/services/bankLinkService';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { COLLECTION_TYPE__HOME, HOME_SLUGS } from '@app/src/taxflow/sections/home/homeConstants';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowOptionItem.scss';

const OPTION_ITEM_TYPES = {
  DYNAMIC: 'dynamic',
  PILLS_SECTION: 'pills-section'
};

const TaxFlowOptionItem = ({
  currentQuestion: originalCurrentQuestion,
  currentAnswer,
  initPlaid,
  isChildQuestion,
  onChange = _.noop,
  onFocus = _.noop,
  onBlur = _.noop,
  setCurrentAnswer,
  setBusinessCode,
  taxFlow,
  history,
  resultLoading,
  jobCategories,
  replaceStrings,
  currentCollectionId,
  setContextLoading
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(originalCurrentQuestion);
  const [filteredList, setFilteredList] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);
  const [isCustomJobSelected, setIsCustomJobSelected] = useState(false);
  const [timeoutId, setTimeoutId] = useState(undefined);
  const [plaidInitiated, setPlaidInitiated] = useState(false);
  const year = useSelector(yearSelector);

  const { data: taxData } = useGetTaxDataQuery({
    year
  });
  const { data: jobsData } = useGetJobsQuery();

  const incomeFreelanceData = _.filter(taxData, { coll_type: INCOME_COLLECTION_TYPES.FREELANCE });

  const skipHomeAddressPreselectFetch = !(
    currentQuestion?.collectionType === COLLECTION_TYPE__HOME &&
    [HOME_SLUGS.NAV_START, HOME_SLUGS.PRESELECT_ADDRESS].includes(currentQuestion?.slug)
  );
  const { data: homeAddressPreselectOptions } = useGetHomeAddressPreselectOptionsQuery(
    { year },
    {
      skip: skipHomeAddressPreselectFetch
    }
  );

  const plaidLinkType = isReactNative() ? 'payment_native' : 'payment';

  /**
   * @desc filter options and set in filteredList to show only filterjob category
   */
  const filterOptions = useCallback(
    (filterString) => {
      if (
        !currentQuestion.question_meta ||
        (currentQuestion.question_meta && currentQuestion.question_meta.length === 0)
      )
        return;

      const cleanedFilterString = filterString.trim().toLowerCase();

      const newList =
        !cleanedFilterString || cleanedFilterString === ''
          ? currentQuestion.question_meta
          : currentQuestion.question_meta.filter(
              (x) => x.text && x.text.trim().toLowerCase().indexOf(cleanedFilterString) !== -1
            );
      setFilteredList(newList);
    },
    [currentQuestion]
  );

  /**
   * Formats job data into option objects.
   */
  const jobOptions = useMemo(() => {
    if (jobsData) {
      return _.chain(jobsData)
        .map((job) => {
          return {
            text: job.name,
            value: job.name,
            businessCode: job.businessCode,
            iconUrl: job.iconUrl
          };
        })
        .uniqBy('value')
        .value();
    }
    return [];
  }, [jobsData]);

  // Add any defined custom jobs to list if freelance income "job name" question
  useEffect(() => {
    if (_.get(originalCurrentQuestion, 'slug') === INCOME_SLUGS.FREELANCE_JOB_NAME) {
      const questionWithNewMeta = {
        ...originalCurrentQuestion,
        question_meta: jobOptions
      };
      setCurrentQuestion(questionWithNewMeta);
    }
  }, [originalCurrentQuestion, jobOptions]);

  useEffect(() => {
    if (!plaidInitiated && _.some(items, { openPlaid: true })) {
      initPlaid({ type: plaidLinkType });
      setPlaidInitiated(true);
    }
  }, [initPlaid, items, plaidInitiated, plaidLinkType]);

  const filteredListNil = _.isNil(filteredList);
  useEffect(() => {
    if (filteredListNil && currentQuestion) {
      filterOptions('');
    }
  }, [currentQuestion, filterOptions, filteredListNil]);

  useEffect(() => {
    setItems(
      _.get(currentQuestion, 'question_meta', []).map((item) => ({
        ...item,
        value: replaceString(_.get(item, 'value', ''), {
          currentYear: getCurrentYear(),
          previousYear: getPreviousYear()
        })
      }))
    );
  }, [currentQuestion]);

  const onClick = async (item) => {
    const result = `${item.value}`;

    setIsCustomJobSelected(false);

    if (item.openPlaid) {
      setCurrentAnswer({ value: null });
    } else if (isChildQuestion) {
      // Component used as child question
      onChange(result);

      if (currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB_NAME && item.businessCode) {
        setBusinessCode(item.businessCode);
      }

      onFocus();
    } else {
      if (currentQuestion) {
        // for single option items, don't toggle on click - clicking on the item again should just move us to the next page.
        const newAnswer = {
          value: result,
          response_text: item.response_text
        };

        setCurrentAnswer(newAnswer);
      }
    }
  };

  /**
   * When the options list includes a pills-section option, we want to filter out the "I don't have X situation" option if the user has at least 1 pill for the situation
   */
  const filterPillsSectionOptions = (options) => {
    const hasMiniDashboard = _.some(options, { type: OPTION_ITEM_TYPES.PILLS_SECTION });
    const collectionTypeTaxData = _.filter(taxData, { coll_type: currentQuestion?.collectionType });
    const hasCollectionTypeInstances = _.some(collectionTypeTaxData, ({ coll_id }) => coll_id !== '0');
    if (hasMiniDashboard && hasCollectionTypeInstances) {
      return options.filter((o) => o.value > 0);
    }
    return options;
  };

  /**
   * @desc search options
   */
  const Search = (filterString) => {
    setSearchText(filterString);
    if (filterString) filterString = filterString.trim();
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        filterOptions(filterString);
      }, 50)
    );
  };

  const renderJobIcon = ({ iconUrl, jobSlug, className }) => {
    if (iconUrl) {
      return <img className={className} src={iconUrl} alt={`${jobSlug}-icon`} />;
    }

    return <BusinessCenterIcon className={className} alt={`${jobSlug} icon`} />;
  };

  const getDynamicOptions = () => {
    if (currentQuestion.slug === HOME_SLUGS.PRESELECT_ADDRESS && homeAddressPreselectOptions?.length > 0) {
      return homeAddressPreselectOptions;
    }
    return [];
  };

  /** Gets whether the given option item is currently selected */
  const getChecked = (item) => {
    const questionHasAnswer = currentAnswer && !_.isNil(currentAnswer.value);
    if (!questionHasAnswer) {
      return false;
    }
    // The job name slug has extra conditions to check
    if (currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB_NAME) {
      const jobNameMatches = jobsData.some(({ name }) => name === item.value && name === currentAnswer.value);
      return jobNameMatches && !isCustomJobSelected;
    }
    return `${item.value}` === `${currentAnswer.value}`;
  };

  /** For the "income-freelance-job-name" slug, get custom response text */
  const getResponseText = (item) => {
    if (currentQuestion.slug !== INCOME_SLUGS.FREELANCE_JOB_NAME || _.isNil(incomeFreelanceData)) {
      return null;
    }
    const currentEarner =
      incomeFreelanceData.find(
        ({ coll_id, slug }) => coll_id === currentCollectionId && slug === INCOME_SLUGS.FREELANCE_WHO
      )?.value === 'my_spouse'
        ? 'SPOUSE'
        : 'ME';
    const currentJob = item.value;
    const checked = getChecked(item);
    const existingJob = jobsData.find(({ name, earner }) => name === currentJob && earner === currentEarner);
    if (!existingJob && checked) {
      const jobName = currentEarner === 'SPOUSE' ? `${currentJob} (spouse)` : `${currentJob} (mine)`;
      return (
        <TaxFlowResponseText
          className='taxflow-option-item-response-text'
          responseTexts={{
            isWarningMessage: false,
            warningTexts: [
              `Keeper will automatically allocate a potion of your deductions to "${jobName}". You can make adjustments in the Deductions tab.`
            ]
          }}
          replaceStrings={replaceStrings}
        />
      );
    }
  };

  const renderItem = (item) => {
    const info = _.get(taxFlow, ['normalizedInfoItems', 'byId', item.info]);

    const itemProps = {
      item,
      handleChange: onClick,
      onBlur,
      checked: getChecked(item),
      className: classNames({ onboarding: isOnboardingQuestion(currentQuestion) }),
      showRightIcon: !!info,
      rightIcon: <TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />,
      question: currentQuestion,
      answer: currentAnswer
    };

    if (item.openPlaid) {
      return (
        <BankLinkPlaid
          type={plaidLinkType}
          key={item.value}
          preSuccess={() => {
            // Show loader
            setContextLoading(true);
          }}
          postSuccess={({ metadata }) => {
            const accountId = _.get(metadata, ['accounts', 0, 'id']);

            if (accountId && item.plaidSuccessRedirect) {
              history.push(
                `${item.plaidSuccessRedirect}?${new URLSearchParams({ account_id: accountId, plaid: true })}`
              );
            } else {
              history.push(item.plaidSuccessRedirect);
            }
          }}
          renderButton={({ open, ready }) => (
            <TaxFlowListItem
              ready={ready}
              {...itemProps}
              handleChange={async (...props) => {
                await onClick(...props);
                trackActivity('question: plaid option selected', {
                  question: currentQuestion.slug
                });
                open();
              }}
            >
              <span>{replaceStrings(item.text)}</span>
            </TaxFlowListItem>
          )}
        />
      );
    }

    if (item.type === OPTION_ITEM_TYPES.PILLS_SECTION) {
      return (
        <div key={item.value} className='taxflow-option-item-pills-section'>
          <div className='taxflow-option-item-pills-section-header'>{item.text}</div>
          <div className='taxflow-option-item-pills-section-pills'>
            <TaxFlowMiniDashboardPills
              collectionType={currentQuestion.collectionType}
              endpointAttribute={_.defaultTo(currentQuestion.endpointAttribute, currentQuestion.slug)}
            />
          </div>
        </div>
      );
    } else if (item.type === OPTION_ITEM_TYPES.DYNAMIC) {
      const dynamicOptions = getDynamicOptions();
      return dynamicOptions.map((option) => {
        const dynamicItemProps = {
          item: option,
          handleChange: onClick,
          onBlur,
          checked: currentAnswer && !_.isNil(currentAnswer.value) && `${option.value}` === `${currentAnswer.value}`,
          showRightIcon: !!info,
          rightIcon: <TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />,
          question: currentQuestion,
          answer: currentAnswer
        };
        return (
          <TaxFlowListItem {...dynamicItemProps} key={option.value} item={option}>
            <span>{replaceStrings(option.text)}</span>
          </TaxFlowListItem>
        );
      });
    }

    const responseText = getResponseText(item);

    return (
      <div key={_.compact([item.value, item.businessCode]).join(' - ')}>
        <TaxFlowListItem {...itemProps}>
          {currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB_NAME &&
            renderJobIcon({ iconUrl: item.iconUrl, jobSlug: item.value, className: 'tax-flow-list-item-job-icon' })}
          <span>{replaceStrings(item.text)}</span>
        </TaxFlowListItem>
        {responseText != null && responseText}
      </div>
    );
  };

  if (resultLoading) return null;

  const selectedAnswer =
    _.size(items) > 10 && currentAnswer ? items.find((m) => `${m.value}` === `${currentAnswer.value}`) : undefined;

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion })}>
      <div className='checkbox-list'>
        {_.size(items) <= 10 || currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB_NAME ? (
          filterPillsSectionOptions(items).map((item) => renderItem(item))
        ) : (
          // big list
          <>
            {selectedAnswer && renderItem(selectedAnswer)}
            <div className='searchbox'>
              <TextField
                className='form-control'
                type='text'
                name='search'
                autoComplete='off'
                value={searchText}
                onChange={(e) => {
                  Search(e.target.value);
                }}
                onInput={(e) => {
                  Search(e.target.value);
                }}
                placeholder='Search...'
              />
            </div>

            {currentQuestion.question_meta &&
              filteredList &&
              currentQuestion.question_meta.length > 0 &&
              filteredList
                .filter((item) => !(selectedAnswer && `${selectedAnswer.value}` === `${item.value}`))
                .map((item) => renderItem(item))}
          </>
        )}
      </div>
      {currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB_NAME && (
        <TaxFlowFormCustomJobItem
          question={currentQuestion}
          setCurrentAnswer={setCurrentAnswer}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          jobCategories={jobCategories}
          isCustomJobSelected={isCustomJobSelected}
          setIsCustomJobSelected={(value) => {
            setIsCustomJobSelected(value);
          }}
          setBusinessCode={setBusinessCode}
        />
      )}
      <TaxValidationWarnings question={currentQuestion} answer={currentAnswer} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    taxFlow: state.taxFlow,
    resultLoading: !_.isNil(props.resultLoading) ? props.resultLoading : state.taxFlow.resultLoading,
    currentCollectionId: currentCollectionIdSelector(state)
  };
};

const mapDispatchToProps = {
  initPlaid,
  setContextLoading,
  setBusinessCode
};

const ConnectedTaxFlowOptionItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowOptionItem);

export default ConnectedTaxFlowOptionItem;
