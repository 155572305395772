import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useIsTaxFilingNextEnabled } from '@app/src/hooks/useIsTaxFilingNextEnabled';
import {
  taxDataOnCurrentPageSelector,
  currentPageHasNewErrorSelector,
  errorsSelector,
  formErrorsSelector
} from '@app/src/selectors/taxValidationSelectors';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import { taxDataSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { isTaxfilingNonskippable } from '@app/src/taxflow/main/utils/mainUtils';
import {
  currentQuestionSelector,
  currentCollectionIdSelector,
  currentAnswerSelector,
  queryResultsSelector,
  statusSelector,
  isAlabamaOrMinnesotaSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';

export const useIsTaxFilingSkipEnabled = () => {
  const currentQuestion = useSelector(currentQuestionSelector);
  const currentAnswer = useSelector(currentAnswerSelector);
  const isAlabamaOrMinnesota = useSelector(isAlabamaOrMinnesotaSelector);
  const queryResults = useSelector(queryResultsSelector);
  const currentCollectionId = useSelector(currentCollectionIdSelector);
  const status = useSelector(statusSelector);
  const errors = useSelector(errorsSelector);
  const formErrors = useSelector(formErrorsSelector);
  const year = useSelector(yearSelector);
  const currentPageHasNewError = useSelector(currentPageHasNewErrorSelector);
  const taxDataOnCurrentPage = useSelector(taxDataOnCurrentPageSelector);
  const currentPageAlreadyHasTaxData = !_.isEmpty(taxDataOnCurrentPage);
  const taxData = useSelector(taxDataSelector);

  const isTaxFilingNextEnabled = useIsTaxFilingNextEnabled();

  const ocrErrors = (errors || []).filter(
    (error) =>
      error.coll_type === currentQuestion.collectionType &&
      error.coll_id === currentCollectionId &&
      error.code === 'ocr_error'
  );

  return (
    !isOnboardingQuestion(currentQuestion) &&
    !isTaxfilingNonskippable(currentQuestion, isAlabamaOrMinnesota) &&
    isTaxFilingNextEnabled &&
    !_.isEmpty(formErrors) &&
    (!isValidInput({
      question: currentQuestion,
      answer: currentAnswer,
      status,
      queryResults,
      currentCollectionId,
      formErrors,
      taxYear: year,
      taxData
    }) ||
      !_.isEmpty(ocrErrors)) &&
    (currentPageHasNewError || currentPageAlreadyHasTaxData)
  );
};
