import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useGetExpenseReviewDetailsQuery } from '@app/src/api/expensesApi';
import { useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import { substitutionsSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  SLUG__DEPENDENT_BIRTHDATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { ENDPOINT_ATTRIBUTE__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { NO_INCOME_TAX_STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

export const useSubstitutedText = ({ text }) => {
  const year = useSelector(yearSelector);
  const otherSubstitutions = useSelector(substitutionsSelector);
  const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });
  const { data: expenseReviewDetails, isLoading: expenseReviewDetailsLoading } = useGetExpenseReviewDetailsQuery({
    year
  });

  if (taxDataLoading || expenseReviewDetailsLoading) {
    return { isLoading: true, substitutedText: null };
  }

  const states = _.chain(taxData).filter({ slug: ENDPOINT_ATTRIBUTE__STATE_RETURN }).map('value').value();
  const taxableStates = _.filter(states, (state) => !NO_INCOME_TAX_STATES.has(state));
  const taxEntities = ['the IRS', ...taxableStates];
  const irsAndState = _.isEmpty(taxableStates)
    ? 'the IRS'
    : `${_.dropRight(taxEntities).join(', ')} and ${_.last(taxEntities)}`;

  const dependents = _.chain(taxData).filter({ coll_type: COLLECTION_TYPE__DEPENDENT }).groupBy('coll_id').value();
  const dependentsUnder13 = _.chain(dependents)
    .filter((dependent) => {
      const birthdate = _.find(dependent, { slug: SLUG__DEPENDENT_BIRTHDATE })?.value;
      return moment(birthdate, 'YYYY-MM-DD').isAfter(moment(`${year - 13}-12-31`, 'YYYY-MM-DD'));
    })
    .value();

  const substitutedText = _.chain(text)
    .replace('{irsAndState}', irsAndState)
    .replace(
      '{deductionsAmount}',
      `$${_.chain(expenseReviewDetails?.deductionsAmount).defaultTo(0).round().value().toLocaleString()}`
    )
    .replace(
      '{dependentsUnder13}',
      `${listFormatter.format(dependentsUnder13.map((dependent) => _.startCase(_.find(dependent, { slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME }).value)))}`
    )
    .replace('{dependentsUnder13 is/are}', dependentsUnder13.length === 1 ? 'is' : 'are')
    .thru((text) =>
      _.reduce(otherSubstitutions, (substitutedText, { name, value }) => substitutedText.replace(name, value), text)
    )
    .value();
  return { isLoading: false, substitutedText };
};
