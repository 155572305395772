import url from 'url';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import {
  mergeCurrentQuestion,
  resetCurrentQuestion as resetCurrentQuestionOrig,
  setBusinessCode,
  setChangedEndpointAttributes,
  setContextLoading,
  setCurrentAnswer,
  setCurrentGetQuestionAnswerLoadingSlug,
  setCurrentQuestion,
  setDesktopSellModalShow,
  setDropzoneFilesReadyForUpload,
  setDropzoneFilesSameForm,
  setDropzoneLoading,
  setIsConfirmationModalOpen,
  setIsQuestionnaireFlow,
  setQuestionWrapper,
  setTaxFlowError,
  setTaxFlowLoader,
  setUpdating
} from '@app/src/actions/taxFlowActions';
import { setCurrentPageHasNewError, setErrors } from '@app/src/actions/taxValidationActions';
import { TAGS } from '@app/src/api/baseApi';
import plaidApi from '@app/src/api/plaidApi';
import profileApi, { updateWorkInfo } from '@app/src/api/profileApi';
import taxDataApi, {
  deleteTaxData,
  updateTaxData,
  getSsnMatched,
  answerIdVerificationQuestions,
  getIdVerificationQuestions,
  getIdVerificationResult,
  getBulkUploadPills,
  createJob,
  updateJob,
  getJobs,
  getUIStage,
  updateUIStage,
  getTaxErrors,
  fileExtension
} from '@app/src/api/taxDataApi';
import {
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_WHO
} from '@app/src/constants/constants';
import { serverUrl } from '@app/src/global/Environment';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { desktopSellModalVisitedSelector } from '@app/src/selectors/assistantSelectors';
import {
  aboutYouEnabledSelector,
  supportAccessOriginSelector,
  taxProgressStageSelector
} from '@app/src/selectors/navigationListSelectors';
import {
  businessCodeSelector,
  isQuestionnaireFlowSelector,
  currentGetQuestionAnswerLoadingSlugSelector,
  stateTaxSlugsSelector,
  isReviewModeSelector,
  allTaxDataUpdatesSelector
} from '@app/src/selectors/taxFlowSelectors';
import {
  taxDataOnCurrentPageSelector,
  filteredErrorsSelector,
  formErrorsSelector
} from '@app/src/selectors/taxValidationSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import {
  taxFilingEnabledSelector,
  developerEnabledSelector,
  idVerificationEnabledSelector,
  aboutYouStatusPrefillSelector,
  humanReviewEnabledSelector,
  idVerificationDisabledSelector
} from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getTaxFilingContentfulEntries } from '@app/src/services/taxFlowContentfulService';
import { getUploadAttemptsStatuses, resetSubmitSsnMatched } from '@app/src/services/taxFlowService';
import { getWorkInfoLazy, postTaxSubmission } from '@app/src/services/workService';
import { setCurrentCollectionId, setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import {
  getNextPathComponentWithSkip,
  getNextPathComponentWithUpload
} from '@app/src/taxflow/collection/services/collectionService';
import {
  flattenedUploadAttemptsSelector,
  formUploadAttemptsSelector,
  isCollectionUploadedSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  savedDefaultAnswerSelector,
  substitutionsWithQuestionSelector,
  allDerivedQuestionsSelector,
  zeroTaxOwedSelector,
  isPreSubmitSelector,
  yearSelector,
  uiStageSelector,
  questionnairePassedQuestionsSelector,
  questionnaireTotalQuestionsSelector,
  allQuestionsSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  dispatchHomeOfficeUpdates,
  getCurrentQuestionData,
  getDeductions,
  getQueryResults,
  updateItemizedDeduction,
  getQuestionnaireQuestionAsQuestion,
  resetCurrentAnswer,
  getCarPriorDepreciation
} from '@app/src/taxflow/main/services/taxFlowDataService';
import { formatIdVerificationQuestions, mergeQuestions } from '@app/src/taxflow/main/utils/idVerificationUtils';
import {
  getAllowedParams,
  getQuestionById,
  getQuestionDelete,
  getQuestionWithSubstitutions,
  get1099JobData,
  getStartedUpdates
} from '@app/src/taxflow/main/utils/mainUtils';
import { deserializeQuestionAnswer, serializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { setSupportAccessOrigin } from '@app/src/taxflow/navigation/actions/navigationActions';
import { getOriginParam } from '@app/src/taxflow/navigation/utils/navigationUtils';
import { CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import {
  getNextPathComponent as getNextPathComponentCar,
  getNextPathComponentMap as getNextPathComponentMapCar,
  getOptionPathComponentMap as getOptionPathComponentMapCar,
  getSlugMap as getSlugMapCar,
  getNextQuery as getNextCarQuery
} from '@app/src/taxflow/sections/car/carUtils';
import {
  ITEMIZED_DEDUCTION_DEFAULTS_SLUGS,
  PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
  SLUG__CREDIT_CHILD_CARE_WHO,
  SLUG__CREDIT_COGS_JOBS,
  SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  SLUG__CREDIT_STUDENT_TUITION_WHO
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  getNextPathComponent as getNextPathComponentCredit,
  getNextPathComponentMap as getNextPathComponentMapCredit,
  getNextQuery as getNextQueryCredit,
  getOptionPathComponentMap as getOptionPathComponentMapCredit,
  getSlugMap as getSlugMapCredit
} from '@app/src/taxflow/sections/credit/utils/creditUtils';
import { HOME_SLUGS } from '@app/src/taxflow/sections/home/homeConstants';
import {
  getNextPathComponent as getNextPathComponentHome,
  getNextPathComponentMap as getNextPathComponentMapHome,
  getOptionPathComponentMap as getOptionPathComponentMapHome,
  getSlugMap as getSlugMapHome,
  getNextQuery as getNextHomeQuery
} from '@app/src/taxflow/sections/home/homeUtils';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  getNextPathComponent as getNextPathComponentIncome,
  getNextPathComponentMap as getNextPathComponentMapIncome,
  getOptionPathComponentMap as getOptionPathComponentMapIncome,
  getSlugMap as getSlugMapIncome
} from '@app/src/taxflow/sections/income/incomeUtils';
import {
  COLLECTION_TYPE__DEPENDENT,
  COLLECTION_TYPE__HOME_ADDRESS,
  COLLECTION_TYPE__SELF,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  PATH_COMPONENT__DEPENDENT_NAV_START,
  PATH_COMPONENT__SELF_DETAIL,
  PATH_COMPONENT__SELF_MARRIAGE,
  PATH_COMPONENT__TAX_HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__SELF_DETAIL,
  SLUG__SELF_MARRIAGE,
  SSN_SLUGS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  getNextPathComponentMap as getNextPathComponentMapPersonal,
  getNextPathComponent as getNextPathComponentPersonal,
  getNextQuery as getNextQueryPersonal,
  getOptionPathComponentMap as getOptionPathComponentMapPersonal,
  getSlugMap as getSlugMapPersonal
} from '@app/src/taxflow/sections/personal/utils/personalUtils';
import {
  SLUG__FIND_WRITE_OFFS,
  SLUG__PREMIUM_START,
  SLUG__BULK_UPLOAD_QUESTIONS,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  PATH_COMPONENT__TAX_HOME,
  COLLECTION_TYPE__SPECIAL,
  PATH_COMPONENT__EXTENSION_CONFIRMATION,
  SLUG__EXTENSION_CONFIRMATION
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  getNextPathComponentMap as getNextPathComponentMapSpecial,
  getOptionPathComponentMap as getOptionPathComponentMapSpecial,
  getSlugMap as getSlugMapSpecial
} from '@app/src/taxflow/sections/special/utils/specialUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapState,
  getNextPathComponent as getNextPathComponentState,
  getNextQuery as getNextQueryState,
  getOptionPathComponentMap as getOptionPathComponentMapState,
  getSlugMap as getSlugMapState
} from '@app/src/taxflow/sections/state';
import {
  COLLECTION_TYPE__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_INCOME,
  SLUG__IL_TUITION_DEPENDENT,
  SLUG__STATE_DEFAULT,
  SLUG__STATE_INCOME,
  SLUG__STATE_NO_INCOME_TAX,
  SLUG__STATE_RETURN,
  SLUG__TEMPLATE_STATE_DONE,
  STATE_SPLIT_SLUGS
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  getNextPathComponentMap as getNextPathComponentMapSubmit,
  getNextPathComponent as getNextPathComponentSubmit,
  getSlugMap as getSlugMapSubmit
} from '@app/src/taxflow/sections/submit';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_DEBIT_MANUAL,
  SLUG__SUBMIT_DEBIT_ROUTING,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_CONFIRM_ID,
  PATH_COMPONENT__SUBMIT_EMAIL_INFO,
  COLLECTION_TYPE__SUBMIT_PIN,
  PATH_COMPONENT__SUBMIT_PIN
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  submitIssueTypeSelector,
  uncheckedSubmitIssueMessagesSelector
} from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { getTaxFilingPaid } from '@app/src/taxflow/sections/submit/services/submitService';
import { setIsNextOrPrevButtonLoading } from '@app/src/taxflow/shared/actions/sharedActions';
import {
  DEFAULT_COLLECTION_ID,
  PATH_COMPONENT__CONTACT_SUPPORT,
  PATH_COMPONENT__DASHBOARD,
  PATH_COMPONENT__LINKED_ACCOUNTS,
  PATH_COMPONENT__PAST_RETURNS,
  PATH_COMPONENT__SETTINGS,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  SLUG__CONTACT_SUPPORT,
  SLUG__LINKED_ACCOUNTS,
  SLUG__PAST_RETURNS,
  SLUG__SETTINGS,
  SLUG__SWITCH_TO_DESKTOP,
  STATE_DROPDOWN_OPTIONS,
  STATE_NAME_MAP,
  STATE_TAX_COLL_TYPE_MAP,
  TAXFLOW_BASE_URL,
  UI_STAGE__OPS_REVIEW,
  UI_STAGE__PURGATORY,
  UI_STAGE__REJECTED_ESC,
  UI_STAGE__USER_ESC
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  deductionsSelector,
  irsPaymentAccountSelector,
  persistErrorSelector,
  queryResultsMapSelector,
  queryResultsSelector,
  resErrorSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  getQueryResultByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

const resetQuestionAnswer = () => (dispatch, getState) => {
  const persistError = persistErrorSelector(getState());
  dispatch(resetCurrentQuestionOrig());
  if (!persistError) {
    dispatch(setTaxFlowError(''));
  }
  dispatch(setErrors([]));
  dispatch(setChangedEndpointAttributes({}));
  dispatch(setCurrentCollectionId(null));
  dispatch(setCurrentPageHasNewError(false));
};

/**
 * Populate dropdown options dynamically (only works for dropdown options that aren't dependent on other inputs
 * currently on the screen)
 * @param {*} question
 */
const getQuestionMetaWithSubstitutions = (question) => (dispatch) => {
  if (question.question_type === CATEGORY_TYPE_STATE) {
    return STATE_DROPDOWN_OPTIONS;
  }

  if (question.slug === SLUG__CREDIT_STUDENT_TUITION_WHO) {
    return dispatch(getPossessiveDependentOptionsIncludingParents());
  }

  if (question.question_type === CATEGORY_TYPE_WHO) {
    return [{ text: 'Select option...', value: '' }, ...question.question_meta];
  }

  if (question.slug === SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS) {
    return dispatch(getDependentOptionsIncludingParents({ includeDefault: true }));
  }

  if (question.slug === SLUG__CREDIT_CHILD_CARE_WHO || question.slug === SLUG__IL_TUITION_DEPENDENT) {
    return dispatch(getDependentOptions({ includeDefault: true }));
  }

  return question.question_meta;
};

const getDependentOptions =
  ({ includeDefault }) =>
  (dispatch, getState) => {
    const queryResults = queryResultsSelector(getState());
    const dependentQueryResults = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    return dependentQueryResults.map((queryResult, i) => ({
      text: _.get(queryResult, ['answer', 'value']),
      value: queryResult.coll_id,
      ...(includeDefault && i === 0 ? { default: 1 } : {})
    }));
  };

const getDependentOptionsIncludingParents =
  ({ includeDefault }) =>
  (dispatch, getState) => {
    const status = statusSelector(getState());

    return [
      {
        text: 'Me',
        value: 'me',
        default: 1
      },
      ...(status === 'married'
        ? [
            {
              text: 'My spouse',
              value: 'my_spouse',
              default: includeDefault ? 1 : 0
            }
          ]
        : []),
      ...dispatch(getDependentOptions({ includeDefault }))
    ];
  };

const getPossessiveDependentOptionsIncludingParents = () => (dispatch, getState) => {
  const status = statusSelector(getState());
  const dependentOptions = dispatch(getDependentOptions({}));

  return [
    {
      text: 'Select option...',
      value: ''
    },
    {
      text: 'Mine',
      value: 'me',
      isMeValue: true
    },
    ...(status === 'married' ? [{ text: "My spouse's", value: 'my_spouse' }] : []),
    ..._.map(dependentOptions, (dependent) => {
      return { ...dependent, text: `${dependent.text}'s` };
    })
  ];
};

const getQuestionMetaWithSubstitutionsRecursive = (question) => (dispatch) => {
  return {
    ...question,
    question_meta: dispatch(getQuestionMetaWithSubstitutions(question)),
    ...(question.sub_question
      ? {
          sub_question: question.sub_question.map((subQuestion) => ({
            ...subQuestion,
            question_meta: dispatch(getQuestionMetaWithSubstitutions(subQuestion))
          }))
        }
      : {})
  };
};

const setCurrentQuestionnaireQuestionAsTheCurrentQuestion =
  ({ currentQuestionnaireQuestion }) =>
  async (dispatch, getState) => {
    const currentQuestion = await dispatch(
      getQuestionnaireQuestionAsQuestion({
        questionnaireQuestion: currentQuestionnaireQuestion
      })
    );
    if (!currentQuestion) {
      await dispatch(advanceToNextQuestionnaireQuestion({}));

      defaultCaptureMessage(
        'No question found, auto-advancing to next question',
        {
          currentQuestionnaireQuestion
        },
        'info'
      );
      return null;
    }
    const substitutions = substitutionsWithQuestionSelector(getState(), {
      question: currentQuestion
    });
    const questionWithSubstitutions = getQuestionWithSubstitutions({
      question: currentQuestion,
      substitutions
    });
    const questionWithNewMeta = dispatch(getQuestionMetaWithSubstitutionsRecursive(questionWithSubstitutions));

    dispatch(setCurrentQuestion(questionWithNewMeta));
    dispatch(resetCurrentAnswer({ questionWithNewMeta }));

    return questionWithNewMeta;
  };

export const getQuestionAnswer =
  ({ slug, location, history, currentQuestionnaireQuestion, isExtensionFlow }) =>
  async (dispatch, getState) => {
    const timeout = setTimeout(() => {
      trackActivity('timeout', { type: 'getQuestionAnswer', question: slug });
    }, 60000);

    try {
      const year = yearSelector(getState());
      dispatch(resetQuestionAnswer({ slug }));

      let currentCollectionId = DEFAULT_COLLECTION_ID;
      const query = qs.parse(location.search.slice(1), { ignoreQueryPrefix: true });
      if (_.has(query, ['collectionId'])) {
        currentCollectionId = query.collectionId;
      }
      dispatch(setCurrentCollectionId(currentCollectionId));

      await dispatch(getTaxFilingContentfulEntries());
      const allQuestions = allDerivedQuestionsSelector(getState());
      const currentQuestion = getQuestionById({ allQuestions, slug });

      dispatch(setCurrentGetQuestionAnswerLoadingSlug(_.get(currentQuestion, 'slug')));

      if (slug === SLUG__BULK_UPLOAD_QUESTIONS) {
        dispatch(setQuestionWrapper(currentQuestion));
        await dispatch(setIsQuestionnaireFlow(true));
        if (_.isNil(currentQuestionnaireQuestion)) {
          history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
          return;
        }
        if (_.isEmpty(flattenedUploadAttemptsSelector(getState()))) {
          await dispatch(getUploadAttemptsStatuses());
        }
        // TODO move ot rtk-query
        await dispatch(getWorkInfoLazy());
        await dispatch(setCurrentQuestionnaireQuestionAsTheCurrentQuestion({ currentQuestionnaireQuestion }));
        dispatch(trackQuestionView(currentQuestionnaireQuestion, { isExtensionFlow }));
        dispatch(setContextLoading(false));
        dispatch(setTaxFlowLoader(false));
        return;
      }

      await dispatch(setIsQuestionnaireFlow(false));

      await Promise.all([
        dispatch(getCurrentQuestionData({ currentQuestion })),
        dispatch(getQueryResults({ currentQuestion }))
      ]);

      const queryResults = queryResultsSelector(getState());
      const queryResultsMap = queryResultsMapSelector(getState());
      const taxFilingEnabled = taxFilingEnabledSelector(getState());
      const developerEnabled = developerEnabledSelector(getState());
      const aboutYouEnabled = aboutYouEnabledSelector(getState());
      const taxFilingPaid = await dispatch(getTaxFilingPaid());
      const jobs = await dispatch(getJobs());

      const currentGetQuestionAnswerLoadingSlug = currentGetQuestionAnswerLoadingSlugSelector(getState());

      await dispatchHomeOfficeUpdates({
        dispatch,
        slug: currentQuestion.slug,
        queryResults,
        collectionId: currentCollectionId
      });

      if (currentQuestion.slug === SLUG__SETTINGS) {
        if (currentGetQuestionAnswerLoadingSlug === SLUG__SETTINGS) {
          history.push(`/${PATH_COMPONENT__SETTINGS}`);
        }
      } else if (currentQuestion.slug === SLUG__LINKED_ACCOUNTS) {
        if (currentGetQuestionAnswerLoadingSlug === SLUG__LINKED_ACCOUNTS) {
          history.push(`/${PATH_COMPONENT__LINKED_ACCOUNTS}`);
        }
      } else if (currentQuestion.slug === SLUG__SWITCH_TO_DESKTOP) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`);
      } else if (taxFilingEnabled) {
        // submit flow payment redirects
        if (
          !taxFilingPaid &&
          (currentQuestion.slug === SLUG__SUBMIT_SIGNATURE || currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION)
        ) {
          const paymentPath = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
          history.push(`/${TAXFLOW_BASE_URL}/${paymentPath}`);
          return;
        }
      } else if (currentQuestion.slug === SLUG__PAST_RETURNS) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__PAST_RETURNS}`);
      } else if (currentQuestion.slug === SLUG__CONTACT_SUPPORT) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__CONTACT_SUPPORT}`);
      } else if (
        (currentQuestion.slug !== SLUG__CONTACT_SUPPORT || currentQuestion.slug !== SLUG__PAST_RETURNS) &&
        !developerEnabled
      ) {
        if (!aboutYouEnabled || currentQuestion.slug !== SLUG__FIND_WRITE_OFFS) {
          // redirect tax filing routes to nav if user not enabled
          await dispatch(advanceToCurrentSectionPage({ history }));
          return;
        }
      }

      if (currentQuestion.slug === SLUG__CREDIT_STANDARD_DEDUCTION && _.isNil(statusSelector(getState()))) {
        history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE}`);
      }

      // update current tax flow state
      if (currentQuestion.slug === SLUG__STATE_DEFAULT || currentQuestion.slug === SLUG__STATE_NO_INCOME_TAX) {
        const currentState = queryResults.find((queryResult) => {
          return queryResult.slug === SLUG__STATE_RETURN && queryResult.coll_id === currentCollectionId;
        });
        await dispatch(setCurrentTaxState(_.get(currentState, ['answer', 'value'])));
      } else if (_.get(currentQuestion, ['template', 'slug']) === SLUG__TEMPLATE_STATE_DONE) {
        const currentState = currentQuestion?.slug?.substring(0, 2).toUpperCase();
        if (currentState && STATE_NAME_MAP[currentState]) {
          await dispatch(setCurrentTaxState(currentState));
        }
      } else {
        const stateTaxCollectionTypes = new Set(
          Object.values(STATE_TAX_COLL_TYPE_MAP).flatMap((collTypes) => collTypes)
        );
        if (currentQuestion.collectionType && stateTaxCollectionTypes.has(currentQuestion.collectionType)) {
          const currentState = currentQuestion.collectionType.substring(0, 2).toUpperCase();
          await dispatch(setCurrentTaxState(currentState));
        } else {
          const primaryState = queryResults.find(
            (result) =>
              result.coll_type === COLLECTION_TYPE__HOME_ADDRESS &&
              result.coll_id === DEFAULT_COLLECTION_ID &&
              result.slug === ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE
          );
          const currentState = _.get(primaryState, ['answer', 'value']);
          if (currentState) {
            await dispatch(setCurrentTaxState(currentState));
          }
        }
      }

      if (currentQuestion.slug === SLUG__PREMIUM_START) {
        await axios.post(`${baseUrl}api/taxes/premium-start-email`, {});
      }

      const substitutions = substitutionsWithQuestionSelector(getState(), {
        question: currentQuestion
      });
      const questionWithSubstitutions = getQuestionWithSubstitutions({
        question: currentQuestion,
        substitutions
      });
      dispatch(setCurrentQuestion(questionWithSubstitutions));

      const questionWithNewMeta = dispatch(getQuestionMetaWithSubstitutionsRecursive(questionWithSubstitutions));
      dispatch(mergeCurrentQuestion(questionWithNewMeta));

      if (questionWithNewMeta.slug === CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE) {
        await dispatch(getCarPriorDepreciation(queryResults, currentCollectionId));
      }

      let currentAnswer = {};
      if (questionWithNewMeta.slug === INCOME_SLUGS.INVEST_INFO) {
        const unificationRecord = queryResults.find(
          (result) => result.slug === INCOME_SLUGS.INVEST_UNIFICATION && result.coll_id === currentCollectionId
        );
        let collectionIds;
        if (unificationRecord) {
          collectionIds = _.get(unificationRecord, ['answer', 'value'], [currentCollectionId]);
        } else {
          collectionIds = [currentCollectionId];
        }

        const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
          collectionIds.forEach((collId) => {
            const queryResult = queryResults.find(
              (qr) =>
                qr.coll_type === subQuestion.collectionType &&
                collId === Number(qr.coll_id) &&
                qr.slug === subQuestion.endpoint_attr
            );

            const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year });
            result = {
              ...result,
              [collId]: {
                ..._.get(result, collId, {}),
                [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
              }
            };
          });

          return result;
        }, {});
        currentAnswer = { value: newValue };
      } else if (questionWithNewMeta.slug === CAR_SLUGS.MILEAGE_SPECIFIC) {
        const carQueryResults = queryResults.filter((item) => item.coll_type === COLLECTION_TYPE__CAR);
        const carCollectionIds = _.uniq(carQueryResults.map((r) => r.coll_id).filter((id) => Number(id) > 0));
        const jobs = get1099JobData({ queryResults });

        const newValue = questionWithNewMeta.sub_question[0].sub_question.reduce((result, subQuestion) => {
          for (const carCollectionId of carCollectionIds) {
            const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year });
            if (subQuestion.slug !== CAR_SLUGS.MILEAGE_SPECIFIC_BUSINESS_MILEAGE) {
              const slug = `${subQuestion.endpoint_attr}-${carCollectionId}`;
              const savedAnswer = carQueryResults.find(
                (qr) => qr.coll_type === subQuestion.collectionType && qr.slug === slug
              );

              result[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
            } else {
              const slugs = jobs.map(
                (job) => `${subQuestion.endpoint_attr}-${job.who}-${job.jobName.replaceAll('-', '')}-${carCollectionId}`
              );
              const jobLevelResults = carQueryResults.filter(
                (qr) => qr.coll_type === subQuestion.collectionType && slugs.includes(qr.slug)
              );
              for (const slug of slugs) {
                const savedAnswer = jobLevelResults.find((r) => r.slug === slug);
                result[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
              }
            }
          }
          return result;
        }, {});
        currentAnswer = { value: newValue };
      } else if (questionWithNewMeta.slug === SLUG__CREDIT_COGS_JOBS) {
        const queryResult = queryResults.find(
          (queryResult) =>
            queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
        );
        const defaultAnswer = deserializeQuestionAnswer({
          question: questionWithNewMeta,
          value: null,
          year
        });
        currentAnswer = _.get(queryResult, ['answer']) || defaultAnswer;
      } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
        const businessCode = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: INCOME_COLLECTION_TYPES.FREELANCE,
          collectionId: currentCollectionId,
          slug: INCOME_SLUGS.FREELANCE_BUSINESS_CODE
        });
        const businessIndustry = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: INCOME_COLLECTION_TYPES.FREELANCE,
          collectionId: currentCollectionId,
          slug: INCOME_SLUGS.FREELANCE_INDUSTRY
        });
        currentAnswer = {
          value: {
            [INCOME_SLUGS.FREELANCE_BUSINESS_CODE]: _.get(businessCode, ['answer']),
            [INCOME_SLUGS.FREELANCE_INDUSTRY]: _.get(businessIndustry, ['answer'])
          }
        };
      } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
        const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
          const queryResult = getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: subQuestion.collectionType,
            collectionId: currentCollectionId,
            slug: subQuestion.endpoint_attr
          });
          const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: subQuestion });
          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year });
          return {
            ...result,
            [subQuestion.slug]: _.get(
              queryResult,
              ['answer'],
              !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
            )
          };
        }, {});
        currentAnswer = { value: newValue };
      } else if (STATE_SPLIT_SLUGS.includes(questionWithNewMeta.slug)) {
        const defaultAnswer = deserializeQuestionAnswer({
          question: questionWithNewMeta,
          value: null,
          queryResults,
          year
        });
        const collectionType =
          questionWithNewMeta.slug === SLUG__STATE_INCOME
            ? COLLECTION_TYPE__STATE_INCOME
            : COLLECTION_TYPE__STATE_EXPENSES;
        const savedAnswers = queryResults.filter(
          (queryResult) =>
            queryResult.coll_type === collectionType &&
            !queryResult.slug.endsWith('started') &&
            !queryResult.slug.endsWith('done')
        );
        const savedAnswerVals = savedAnswers.reduce((values, savedAnswer) => {
          return {
            ...values,
            [savedAnswer.slug]: {
              value: parseFloat(_.get(savedAnswer, ['answer', 'value'])),
              slug: savedAnswer.slug,
              endpoint_attr: savedAnswer.slug
            }
          };
        }, {});

        currentAnswer = {
          value: {
            ...defaultAnswer.value,
            ...savedAnswerVals
          }
        };
      } else if (questionWithNewMeta.slug === SLUG__STATE_RETURN) {
        currentAnswer = deserializeQuestionAnswer({ question: questionWithNewMeta, value: null, year });
      } else {
        const aboutYouStatusPredictions = aboutYouStatusPrefillSelector(getState());

        const queryResult = queryResults.find(
          (queryResult) =>
            queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
        );
        const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: questionWithNewMeta });
        const defaultAnswer = deserializeQuestionAnswer({
          question: questionWithNewMeta,
          value: null,
          aboutYouStatusPredictions,
          year
        });
        const prefilledAnswer = _.isNil(savedDefaultAnswer) ? defaultAnswer : savedDefaultAnswer;
        currentAnswer = _.get(queryResult, ['answer']) || prefilledAnswer;
      }

      if (currentQuestion.slug === INCOME_SLUGS.FREELANCE_JOB) {
        const jobName = _.get(queryResultsMap, [
          INCOME_COLLECTION_TYPES.FREELANCE,
          currentCollectionId,
          INCOME_SLUGS.FREELANCE_JOB_NAME
        ]);
        const businessCode = _.chain(jobs)
          .filter(({ name }) => name === jobName)
          .map('businessCode')
          .compact()
          .first()
          .value();
        dispatch(setBusinessCode(businessCode));
      } else {
        dispatch(setBusinessCode(null));
      }

      dispatch(setCurrentAnswer(currentAnswer));

      if (currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID) {
        const idVerificationQuestions = await dispatch(getIdVerificationQuestions());
        if (idVerificationQuestions === 'error') {
          await dispatch(advanceToNextUrl({ history, location }));
        } else {
          const mergedObj = mergeQuestions(currentQuestion, formatIdVerificationQuestions(idVerificationQuestions));
          dispatch(setCurrentQuestion(mergedObj));
        }
      }

      if (currentQuestion.slug !== SLUG__PAST_RETURNS) {
        dispatch(trackQuestionView(currentQuestion, { isExtensionFlow }));
      }

      if (!desktopSellModalVisitedSelector(getState()) && !isReactNative()) {
        dispatch(setDesktopSellModalShow(true));
      }
      // Only show errors from the backend if this page already contained tax data.
      const errors = await dispatch(getTaxErrors({ year }));
      const taxDataOnCurrentPage = taxDataOnCurrentPageSelector(getState());
      if (!_.isEmpty(taxDataOnCurrentPage)) {
        dispatch(setErrors(errors));
      }
    } catch (e) {
      notify('Something went wrong.');
      defaultCaptureException(e);
    } finally {
      dispatch(setContextLoading(false));
      dispatch(setTaxFlowLoader(false));

      clearTimeout(timeout);
      dispatch(setCurrentGetQuestionAnswerLoadingSlug(null));
    }
  };

export const trackQuestionView = (currentQuestion, properties) => (dispatch, getState) => {
  const isReviewMode = isReviewModeSelector(getState());
  const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
  const uiStage = uiStageSelector(getState());
  const taxProgressStage = taxProgressStageSelector(getState());
  const errors = filteredErrorsSelector(getState());
  const currentCollectionId = currentCollectionIdSelector(getState());
  const uniqueValidationErrors = _.uniqBy(errors, 'coll_type').map((error) => {
    return error.coll_type;
  });

  let supportAccessOrigin = supportAccessOriginSelector(getState());
  if (supportAccessOrigin && currentQuestion.slug !== SLUG__CONTACT_SUPPORT) {
    supportAccessOrigin = null;
    dispatch(setSupportAccessOrigin(null));
  }

  const question = isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug;
  let title = currentQuestion.title;

  trackActivity('question: view', {
    flow: currentQuestion.flow,
    question,
    title,
    isReviewMode,
    taxProgressStage,
    uiStage,
    collectionTypeErrors: uniqueValidationErrors,
    totalErrors: errors.length,
    currentCollectionId,
    ...(isQuestionnaireFlow && {
      clarifyingQuestionSlug: _.get(currentQuestion, 'slug'),
      clarifyingQuestionProgress: questionnairePassedQuestionsSelector(getState()),
      clarifyingQuestionsCount: questionnaireTotalQuestionsSelector(getState())
    }),
    ...(supportAccessOrigin && { origin: supportAccessOrigin }),
    ...properties
  });
};

export const trackQuestionAnswer =
  ({ type, submitType, modalQuestion, properties, answer, skipped = false } = {}) =>
  (_dispatch, getState) => {
    const currentQuestion = modalQuestion || currentQuestionSelector(getState());
    const currentAnswer = answer || currentAnswerSelector(getState());
    const currentCollectionId = currentCollectionIdSelector(getState());
    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
    const filingStatus = statusSelector(getState());

    let serializedAnswer = serializeQuestionAnswer({ question: currentQuestion, answer: currentAnswer, filingStatus });
    if (currentQuestion.slug === SLUG__SUBMIT_DEBIT_MANUAL) {
      serializedAnswer = _.get(currentAnswer, ['value', SLUG__SUBMIT_DEBIT_ROUTING, 'value']);
    } else if (currentQuestion.slug === SLUG__SUBMIT_DEBIT) {
      if (_.get(currentAnswer, ['value']) === 'ach') {
        const paymentAccount = irsPaymentAccountSelector(getState());
        serializedAnswer = _.get(paymentAccount, 'institution_name');
      } else {
        serializedAnswer = _.get(currentAnswer, ['value']);
      }
    } else if (currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
      // Don't serialize
      serializedAnswer = _.chain(currentAnswer)
        .get('value')
        .mapValues((subQuestionAnswer) => {
          const answer = _.get(subQuestionAnswer, 'value');
          if (_.isNil(answer) || answer === '') {
            return null;
          }

          return Number(answer);
        })
        .value();
    } else if (
      currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM &&
      currentQuestion.slug !== CAR_SLUGS.MILEAGE_SPECIFIC
    ) {
      serializedAnswer = _.flatMap(currentQuestion.sub_question, (subQuestion) => {
        const subAnswer = _.get(currentAnswer, ['value', subQuestion.slug]);
        // Don't send ssn #'s to analytics, send boolean if it's filled out
        if (SSN_SLUGS.includes(subQuestion.slug)) {
          return [
            {
              slug: subQuestion.endpoint_attr,
              value: Boolean(subAnswer)
            }
          ];
        }
        const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer, filingStatus });
        return [
          {
            slug: subQuestion.endpoint_attr,
            value: !_.isNil(serializedSubAnswer) ? serializedSubAnswer : ''
          }
        ];
      });
    }

    const analyticsProperties = {
      flow: currentQuestion.flow,
      question: isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug,
      title: currentQuestion.title,
      answer: serializedAnswer,
      currentCollectionId,
      ...(isQuestionnaireFlow && {
        clarifyingQuestionSlug: currentQuestion.slug,
        clarifyingQuestionProgress: questionnairePassedQuestionsSelector(getState())
      }),
      ...(submitType ? { submitType } : {}),
      ...properties
    };

    if (type === 'invalid') {
      const formErrors = formErrorsSelector(getState());

      trackActivity('question: validation error', {
        ...analyticsProperties,
        errorSlugs: Object.keys(formErrors),
        errorCount: Object.keys(formErrors).length
      });
    } else {
      trackActivity('question: answer', { ...analyticsProperties, skipped });
    }
  };

export const trackUiStageChange =
  ({ prevUiStage, newUiStage, origin }) =>
  () => {
    trackActivity('tax filing ui stage changed', {
      prevUiStage,
      uiStage: newUiStage,
      timestamp: moment().format(),
      origin
    });
  };

/**
 * When updating taxData, sometimes we have to trigger other updates. This function handles
 * dispatching those updates.
 */
const triggerOtherUpdates =
  ({ taxData, queryResults }) =>
  async (dispatch) => {
    // If list of tax data updates contains the job name slug, then possibly create a new job.
    const jobName = taxData.find(
      ({ coll_type, slug }) =>
        coll_type === INCOME_COLLECTION_TYPES.FREELANCE && slug === INCOME_SLUGS.FREELANCE_JOB_NAME
    );
    const jobs = await dispatch(getJobs());
    if (jobName && !_.isEmpty(jobName.value)) {
      const jobObject = jobs.find(({ name }) => name === jobName.value);
      const earnerResult = queryResults.find(
        ({ coll_id, slug }) => coll_id === jobName.coll_id && slug === INCOME_SLUGS.FREELANCE_WHO
      )?.answer?.value;
      const earner = earnerResult === 'my_spouse' ? 'SPOUSE' : 'ME';
      const createJobPayload = {
        name: jobName.value,
        earner,
        contentfulSlug: jobObject?.contentfulSlug,
        businessCode: jobObject?.businessCode
      };
      await dispatch(createJob(createJobPayload));
    }

    // If list of tax data updates contains the job business code slug, then update the job
    const businessCodeAnswer = taxData.find(
      ({ coll_type, slug }) =>
        coll_type === INCOME_COLLECTION_TYPES.FREELANCE && slug === INCOME_SLUGS.FREELANCE_BUSINESS_CODE
    );
    if (businessCodeAnswer && !_.isNaN(Number(businessCodeAnswer.value))) {
      // Get necessary data from query results
      const jobName = queryResults.find(
        ({ coll_type, coll_id, slug }) =>
          coll_type === INCOME_COLLECTION_TYPES.FREELANCE &&
          coll_id === businessCodeAnswer.coll_id &&
          slug === INCOME_SLUGS.FREELANCE_JOB_NAME
      )?.answer?.value;
      const earnerResult = queryResults.find(
        ({ coll_id, slug }) => coll_id === businessCodeAnswer.coll_id && slug === INCOME_SLUGS.FREELANCE_WHO
      )?.answer?.value;
      const earner = earnerResult === 'my_spouse' ? 'SPOUSE' : 'ME';

      // Find the job object being updated
      const jobObject = jobs.find((job) => job.name === jobName && job.earner === earner && job.businessCode == null);
      if (jobObject) {
        const updateJobPayload = {
          id: jobObject.id,
          businessCode: Number(businessCodeAnswer.value)
        };
        await dispatch(updateJob(updateJobPayload));
      }
    }

    // If the list of tax data updates contains the car-mileage-estimation-work-percent slug, update workinfo
    const carMileagePercent = taxData.find(
      ({ coll_type, slug }) => coll_type === COLLECTION_TYPE__CAR && slug === CAR_SLUGS.MILEAGE_ESTIMATION_WORK_PERCENT
    )?.value;
    if (carMileagePercent != null) {
      await dispatch(updateWorkInfo({ percent_car: carMileagePercent }));
    }
  };

export const updateQuestionAnswer =
  ({ type, handleErrors, isExtensionFlow }) =>
  async (dispatch, getState) => {
    const start = performance.now();

    await dispatch(setUpdating(true));

    const currentQuestion = currentQuestionSelector(getState());
    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
    const year = yearSelector(getState());
    const previousFormErrors = formErrorsSelector(getState());
    const taxDataOnCurrentPage = taxDataOnCurrentPageSelector(getState());
    const currentPageAlreadyHasTaxData = !_.isEmpty(taxDataOnCurrentPage);

    const timeout = setTimeout(() => {
      trackActivity('timeout', {
        type: 'updateQuestionAnswer',
        question: isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug
      });
    }, 60000);
    const persistError = persistErrorSelector(getState());
    let hasNewError = false;

    try {
      if (!persistError) {
        dispatch(setTaxFlowError(''));
      }

      const currentCollectionId = currentCollectionIdSelector(getState());
      const currentAnswer = currentAnswerSelector(getState());
      const queryResults = queryResultsSelector(getState());
      const businessCode = businessCodeSelector(getState());

      // track invalid collection id issue
      if (currentCollectionId === 'NaN' || currentCollectionId === 'null') {
        defaultCaptureException('invalid collection id');
      }

      const taxDataUpdates = allTaxDataUpdatesSelector(getState());

      if (!_.isEmpty(taxDataUpdates)) {
        await dispatch(triggerOtherUpdates({ taxData: taxDataUpdates, queryResults }));
        await dispatch(updateTaxData({ taxData: taxDataUpdates, generateSharedCollectionId: false, year }));
      }

      const newErrors = await dispatch(getTaxErrors({ year }));
      await dispatch(setErrors(newErrors));

      const deletions = getQuestionDelete({
        question: currentQuestion,
        collectionId: currentCollectionId,
        answer: currentAnswer,
        updates: taxDataUpdates,
        queryResults,
        businessCode,
        year
      });

      for (const deletion of deletions) {
        await dispatch(deleteTaxData({ ...deletion, year }));
      }

      if (currentQuestion.slug === SLUG__SELF_DETAIL || currentQuestion.slug === SLUG__HOME_ADDRESS_DETAIL) {
        const submitSsnMatched = await dispatch(getSsnMatched());
        if (submitSsnMatched !== true) {
          dispatch(resetSubmitSsnMatched());
        }
      } else if (ITEMIZED_DEDUCTION_DEFAULTS_SLUGS.includes(currentQuestion.slug)) {
        await dispatch(updateItemizedDeduction());
      } else if (currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
        await dispatch(getDeductions());
      } else if (currentQuestion.slug === SLUG__SUBMIT_EMAIL_INFO) {
        dispatch(setIsNextOrPrevButtonLoading(true));
        await dispatch(plaidApi.util.invalidateTags([TAGS.PAID, TAGS.ACCOUNT_DETAILS]));
      } else if (currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION) {
        await dispatch(setIsConfirmationModalOpen(true));
      } else if (currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID) {
        const questions = currentQuestionSelector(getState());
        const answers = currentAnswerSelector(getState());
        await dispatch(answerIdVerificationQuestions({ questions, answers }));
        await dispatch(profileApi.util.invalidateTags([TAGS.CAMPAIGN]));
        const idVerificationResult = await dispatch(getIdVerificationResult());
        await dispatch(profileApi.util.invalidateTags([{ type: TAGS.UI_STAGE, id: year }]));
        const uiStage = await dispatch(getUIStage({ year }));

        if (idVerificationResult === 'pass') {
          await dispatch(postTaxSubmissionAndUpdateUiStage({ currentUiStage: uiStage }));
        }
      } else if (currentQuestion.slug === SLUG__EXTENSION_CONFIRMATION) {
        await dispatch(fileExtension({ year }));
      } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_MULTI_IMAGE) {
        await dispatch(setDropzoneFilesSameForm(currentAnswer.value === '1'));
        await dispatch(setDropzoneFilesReadyForUpload(true));
        await dispatch(setDropzoneLoading(true));
      } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_PHOTO_CAPTURE) {
        await dispatch(setDropzoneFilesReadyForUpload(true));
        await dispatch(setDropzoneLoading(true));
      }

      if (handleErrors && _.isEmpty(taxDataUpdates)) {
        dispatch(trackQuestionAnswer({ type, properties: { isExtensionFlow } }));
      } else if (handleErrors && !_.isEmpty(taxDataUpdates)) {
        hasNewError = await dispatch(
          handleFormErrors({ previousFormErrors, currentPageAlreadyHasTaxData, isExtensionFlow })
        );
      }
    } catch (e) {
      notify(e.message);
      await dispatch(setTaxFlowError(e));
      defaultCaptureException(e);
    } finally {
      const end = performance.now();
      trackActivity('load time: updateQuestionAnswer', {
        loadTime: end - start,
        question: _.get(currentQuestion, ['slug'])
      });
      clearTimeout(timeout);
      dispatch(setUpdating(false));
      dispatch(setCurrentPageHasNewError(hasNewError));
      return hasNewError;
    }
  };

const handleFormErrors =
  ({ previousFormErrors, currentPageAlreadyHasTaxData, isExtensionFlow }) =>
  (dispatch, getState) => {
    const isUpdatedFormValid = _.isEmpty(formErrorsSelector(getState()));

    if (isUpdatedFormValid) {
      dispatch(trackQuestionAnswer({ properties: { isExtensionFlow } }));
      return false;
    }

    const currentFormErrors = formErrorsSelector(getState());

    if (_.isEqual(previousFormErrors, currentFormErrors) && !isUpdatedFormValid && currentPageAlreadyHasTaxData) {
      // Skip form
      dispatch(trackQuestionAnswer({ skipped: true, properties: { isExtensionFlow } }));
      return false;
    }

    dispatch(trackQuestionAnswer({ type: 'invalid', properties: { isExtensionFlow } }));
    // Set loading false when page is persisted and not navigated from due to errors
    dispatch(setIsNextOrPrevButtonLoading(false));
    return true;
  };

export const updateQuestionStarted = () => async (_dispatch, getState) => {
  const question = currentQuestionSelector(getState());
  const collectionId = currentCollectionIdSelector(getState());
  const queryResults = queryResultsSelector(getState());
  const year = yearSelector(getState());

  const updates = getStartedUpdates({
    question,
    collectionId,
    queryResults
  });

  if (!_.isEmpty(updates)) {
    await _dispatch(updateTaxData({ taxData: updates, generateSharedCollectionId: false, year }));
  }
};

const getNextUrlWithValidParams =
  ({ location }) =>
  async (dispatch, getState) => {
    const { urlStr, nextCollectionId, questionnaireEarlyExit } = await dispatch(getNextUrl({ location }));
    if (!urlStr) {
      return { urlStr, nextSlug: null, questionnaireEarlyExit: true };
    }
    const urlObj = url.parse(urlStr, true);
    const pathComponents = urlObj.pathname.split('/');

    if (pathComponents.length === 2 && pathComponents[1] === PATH_COMPONENT__DASHBOARD) {
      return {
        urlStr: url.format({
          pathname: urlObj.pathname,
          query: _.pick(urlObj.query, ['taxYear'])
        }),
        nextSlug: null,
        nextCollectionId,
        questionnaireEarlyExit: true
      };
    }

    const slugMap = {
      ...getSlugMapSpecial(),
      ...getSlugMapHome(),
      ...getSlugMapCar(),
      ...getSlugMapPersonal(),
      ...getSlugMapIncome(),
      ...getSlugMapCredit(),
      ...getSlugMapSubmit(),
      ...getSlugMapState()
    };
    const slug = slugMap[pathComponents[2]];
    const currentSlug = _.get(currentQuestionSelector(getState()), 'slug');
    return {
      urlStr: url.format({
        pathname: urlObj.pathname,
        query: _.pick(urlObj.query, getAllowedParams({ slug, currentSlug }))
      }),
      nextSlug: slug,
      nextCollectionId,
      questionnaireEarlyExit
    };
  };

const getNextUrl =
  ({ location }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    const currentQuestion = currentQuestionSelector(getState());
    const currentAnswer = currentAnswerSelector(getState());
    const queryResults = queryResultsSelector(getState());

    if (currentQuestion.slug === SLUG__FIND_WRITE_OFFS) {
      return { urlStr: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}` };
    }

    const currentQuery = qs.parse(location.search.slice(1), { ignoreQueryPrefix: true });

    let currentCollectionId = currentCollectionIdSelector(getState());
    const [idVerificationResult, bulkUploadPills] = await Promise.all([
      dispatch(getIdVerificationResult()),
      dispatch(getBulkUploadPills({ year }))
    ]);
    const deductions = deductionsSelector(getState());
    const businessCode = businessCodeSelector(getState());
    const stateTaxSlugs = stateTaxSlugsSelector(getState());
    const formUploadAttempts = formUploadAttemptsSelector(getState());
    const uiStage = uiStageSelector(getState());
    const submitIssueType = submitIssueTypeSelector(getState());
    const uncheckedSubmitIssues = uncheckedSubmitIssueMessagesSelector(getState());
    const irsPaymentAccount = irsPaymentAccountSelector(getState());
    const idVerificationEnabled = idVerificationEnabledSelector(getState());
    const idVerificationDisabled = idVerificationDisabledSelector(getState());
    const verifyIdEnabled = !(idVerificationDisabled || !idVerificationEnabled);
    const humanReviewEnabled = humanReviewEnabledSelector(getState());
    const zeroTaxOwed = zeroTaxOwedSelector(getState());
    const useQuestionnaireNavigationLogic =
      isQuestionnaireFlowSelector(getState()) || _.get(currentQuery, 'origin') === 'questionnaire-summary';
    const isPreSubmit = isPreSubmitSelector(getState());

    const isCollectionUploaded = isCollectionUploadedSelector(
      currentCollectionId,
      currentQuestion.collectionType
    )(getState());

    const isCurrentCollectionAccessibleInBulk = _.some(bulkUploadPills, {
      collectionType: currentQuestion.collectionType,
      collectionId: currentCollectionId
    });

    let nextPathComponent = null;
    // Should we ignore nextPathComponent if in the questionnaire flow, and instead advance to the next pre-generated question?
    let questionnaireEarlyExit = false;

    // Simple cases wherein one page always navigates to a fixed next page
    const nextPathComponentMap = {
      ...getNextPathComponentMapCar(),
      ...getNextPathComponentMapCredit(),
      ...getNextPathComponentMapHome(),
      ...getNextPathComponentMapIncome(),
      ...getNextPathComponentMapPersonal(),
      ...getNextPathComponentMapSpecial(),
      ...getNextPathComponentMapSubmit(),
      ...getNextPathComponentMapState()
    };
    if (_.has(nextPathComponentMap, [currentQuestion.slug])) {
      const questionnaireNextPathComponent = _.get(nextPathComponentMap, [
        currentQuestion.slug,
        'questionnaireNextPathComponent'
      ]);
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) ||
        _.get(nextPathComponentMap, [currentQuestion.slug, 'nextPathComponent']);
      questionnaireEarlyExit = _.get(nextPathComponentMap, [currentQuestion.slug, 'questionnaireEarlyExit']);
    }

    // Simple cases wherein one page always navigates to a fixed next page as a function of a selected option on the page
    const optionPathComponentMap = {
      ...getOptionPathComponentMapSpecial(),
      ...getOptionPathComponentMapCar(),
      ...getOptionPathComponentMapCredit(),
      ...getOptionPathComponentMapHome(),
      ...getOptionPathComponentMapIncome(),
      ...getOptionPathComponentMapPersonal(),
      ...getOptionPathComponentMapState()
    };

    if (_.has(optionPathComponentMap, [currentQuestion.slug])) {
      const questionnaireNextPathComponent = _.get(optionPathComponentMap, [
        currentQuestion.slug,
        _.get(currentAnswer, ['value']),
        'questionnaireNextPathComponent'
      ]);
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) ||
        _.get(optionPathComponentMap, [currentQuestion.slug, _.get(currentAnswer, ['value']), 'nextPathComponent']);
      questionnaireEarlyExit = _.get(optionPathComponentMap, [
        currentQuestion.slug,
        _.get(currentAnswer, ['value']),
        'questionnaireEarlyExit'
      ]);
    }

    // Special cases requiring more than a simple state transition model
    if (!nextPathComponent) {
      const {
        nextPathComponent: updatedNextPathComponent,
        questionnaireEarlyExit: updatedQuestionnaireEarlyExit,
        questionnaireNextPathComponent
      } = _.reduce(
        [
          getNextPathComponentCar,
          getNextPathComponentHome,
          getNextPathComponentCredit,
          getNextPathComponentIncome,
          getNextPathComponentSubmit,
          getNextPathComponentState,
          getNextPathComponentPersonal
        ],
        ({ nextPathComponent, questionnaireEarlyExit, questionnaireNextPathComponent }, getNextPathComponent) => {
          if (_.isNil(nextPathComponent)) {
            return getNextPathComponent({
              question: currentQuestion,
              answer: currentAnswer,
              collectionId: currentCollectionId,
              queryResults,
              deductions,
              businessCode,
              currentCollectionId,
              uploadAttempts: formUploadAttempts,
              uiStage,
              submitIssueType,
              uncheckedSubmitIssues,
              irsPaymentAccount,
              idVerificationResult,
              verifyIdEnabled,
              humanReviewEnabled,
              zeroTaxOwed,
              stateTaxSlugs,
              isPreSubmit,
              isCurrentCollectionAccessibleInBulk
            });
          }
          return { nextPathComponent, questionnaireEarlyExit, questionnaireNextPathComponent };
        },
        { nextPathComponent, questionnaireEarlyExit }
      );
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) || updatedNextPathComponent;
      questionnaireEarlyExit = updatedQuestionnaireEarlyExit;
    }

    if (nextPathComponent) {
      nextPathComponent = await dispatch(
        getNextPathComponentWithSkip({
          pathComponent: nextPathComponent,
          collectionType: currentQuestion.collectionType,
          collectionId: currentCollectionId,
          isCollectionUploaded
        })
      );
    }

    if (nextPathComponent) {
      nextPathComponent = await dispatch(
        getNextPathComponentWithUpload({
          pathComponent: nextPathComponent
        })
      );
    }

    let basePathComponent = TAXFLOW_BASE_URL;

    let nextQuery = {
      collectionId: currentCollectionId,
      ...(_.has(currentQuery, 'origin') ? { origin: currentQuery.origin } : {}),
      ...getNextQueryPersonal({ nextPathComponent }),
      ...getNextQueryCredit({
        question: currentQuestion,
        nextPathComponent
      }),
      ...getNextQueryState({
        question: currentQuestion,
        queryResults,
        collectionId: currentCollectionId
      }),
      ...getNextCarQuery({ nextPathComponent }),
      ...getNextHomeQuery({ nextPathComponent })
    };

    if (nextPathComponent) {
      return {
        urlStr: url.format({
          pathname:
            nextPathComponent === PATH_COMPONENT__DASHBOARD
              ? `/${PATH_COMPONENT__DASHBOARD}`
              : `/${basePathComponent}/${nextPathComponent}`,
          query: nextQuery
        }),
        questionnaireEarlyExit,
        nextCollectionId: _.get(nextQuery, 'collectionId')
      };
    }

    return { urlStr: null };
  };

export const advanceToNextQuestionnaireQuestion =
  ({ nextSlug, nextCollectionId, questionnaireEarlyExit, history }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    const currentCollectionId = currentCollectionIdSelector(getState());
    const navSlugs = [...Object.values(CAR_SLUGS), ...Object.values(HOME_SLUGS)];
    // If nav logic provides a next question and we want to include it in the questionnaire flow (not early exit), advance to such "follow-up" question
    // Else, advance to the next generated questionnaire question
    const maybeFollowUpQuestion = !_.isNil(nextSlug) &&
      !questionnaireEarlyExit && {
        type: 'more-info',
        coll_id: currentCollectionId !== nextCollectionId || navSlugs.includes(nextSlug) ? nextCollectionId : undefined,
        slug: nextSlug
      };
    const {
      data: { questionnaireProgress }
    } = await dispatch(
      taxDataApi.endpoints.progressToNextQuestionnaireQuestion.initiate({ maybeFollowUpQuestion, year })
    );
    if (_.get(questionnaireProgress, 'questionnaireComplete', true)) {
      dispatch(setIsQuestionnaireFlow(false));
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
    }
  };

export const advanceToNextUrl =
  ({ history, location }) =>
  async (dispatch, getState) => {
    const currentQuestion = currentQuestionSelector(getState());
    const slug = _.get(currentQuestion, 'slug');

    const { urlStr, nextSlug, nextCollectionId, questionnaireEarlyExit } = await dispatch(
      getNextUrlWithValidParams({ location })
    );

    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
    if (isQuestionnaireFlow) {
      await dispatch(
        advanceToNextQuestionnaireQuestion({
          nextSlug,
          nextCollectionId,
          questionnaireEarlyExit,
          history
        })
      );
      return;
    }
    const originParam = getOriginParam({ location });

    // Case: Extensions flow and the current subflow is completed. Navigate to the next subflow in the extensions flow
    if (originParam === 'extension' && (_.isEmpty(nextSlug) || nextSlug === 'home')) {
      const nextExtensionFilingQuestionPathComponent = dispatch(getNextExtensionFilingQuestionPathComponent({ slug }));
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextExtensionFilingQuestionPathComponent}`,
          query: {
            origin: nextExtensionFilingQuestionPathComponent === PATH_COMPONENT__TAX_HOME ? undefined : 'extension'
          }
        })
      );
      return;
    }

    if (questionnaireEarlyExit && originParam === 'questionnaire-summary') {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
      return;
    }

    if (!nextSlug || (originParam === 'home' && nextSlug === INCOME_SLUGS.FREELANCE_1099K_EXPENSES_INFO)) {
      if (originParam === 'home') {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
      } else {
        await dispatch(advanceToCurrentSectionPage({ history }));
      }
      return;
    }

    const resError = resErrorSelector(getState());
    const persistError = persistErrorSelector(getState());
    if (!persistError && resError) {
      return;
    }
    if (isReactNative()) {
      const urlObj = url.parse(urlStr);
      if (urlObj.pathname === `/${PATH_COMPONENT__DASHBOARD}`) {
        sentMsgToReactNative('home');
        return;
      }
    }

    // Temporary questions which the users should not be able to return to
    if ([SLUG__BULK_UPLOAD_MULTI_IMAGE, SLUG__BULK_UPLOAD_PHOTO_CAPTURE].includes(slug)) {
      history.goBack();
      return;
    }

    history.push(urlStr);
  };

export const advanceToCurrentSectionPage =
  ({ history, replaceLocation = false }) =>
  async () => {
    replaceLocation
      ? history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`)
      : history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
  };

export const postTaxSubmissionAndUpdateUiStage =
  ({ currentUiStage }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    let submitType = 'initial_submit';
    let updatedUiStage = UI_STAGE__PURGATORY;

    const user = userSelector(getState());
    const isKfaUser = user?.isKfaUser;
    if (isKfaUser) {
      updatedUiStage = UI_STAGE__OPS_REVIEW;
    } else if (currentUiStage === UI_STAGE__USER_ESC) {
      submitType = 'audit_resubmit';
      updatedUiStage = UI_STAGE__OPS_REVIEW;
    } else if (currentUiStage === UI_STAGE__REJECTED_ESC) {
      submitType = 'irs_resubmit';
      updatedUiStage = UI_STAGE__OPS_REVIEW;
    }

    // send tax submission to backend
    await dispatch(postTaxSubmission(submitType));

    // update ui
    await dispatch(updateUIStage({ uiStage: updatedUiStage, year }));
    dispatch(
      trackUiStageChange({
        prevUiStage: currentUiStage,
        newUiStage: updatedUiStage,
        origin: `tax filing ui (user submission - ${submitType})`
      })
    );

    // await ui stage change
    await dispatch(getUIStage({ year }));
  };

/**
 * Derive the next question slug in the extensions flow
 *
 * Intra-collection-type, we'll use our traditional navigation logic (ex 'self-detail' -> 'self-id').
 * This function provides navigation between collection types within the extensions flow (ex 'self-marriage' -> 'home-address-detail')
 */
const getNextExtensionFilingQuestionPathComponent =
  ({ slug }) =>
  (dispatch, getState) => {
    const allQuestions = allQuestionsSelector(getState());

    // Case: Last question in the extensions flow. Route to home
    if (slug === SLUG__EXTENSION_CONFIRMATION) {
      return PATH_COMPONENT__TAX_HOME;
    }

    // Case: "self" collection type question 'self-marriage' - route to home address detail (instead of 'self-marriage')
    if (slug === SLUG__SELF_MARRIAGE) {
      return PATH_COMPONENT__TAX_HOME_ADDRESS_DETAIL;
    }

    const EXTENSIONS_FLOW_COLLECTION_TYPES_TO_NEXT_QUESTION = {
      [COLLECTION_TYPE__SPECIAL]: PATH_COMPONENT__SELF_DETAIL,
      [COLLECTION_TYPE__SELF]: PATH_COMPONENT__SELF_MARRIAGE,
      [COLLECTION_TYPE__SPOUSE]: PATH_COMPONENT__TAX_HOME_ADDRESS_DETAIL,
      [COLLECTION_TYPE__HOME_ADDRESS]: PATH_COMPONENT__DEPENDENT_NAV_START,
      [COLLECTION_TYPE__DEPENDENT]: PATH_COMPONENT__SUBMIT_PIN,
      [COLLECTION_TYPE__SUBMIT_PIN]: PATH_COMPONENT__EXTENSION_CONFIRMATION
    };
    const currentQuestion = _.find(allQuestions, { slug });
    const collectionType = _.get(currentQuestion, 'collectionType');
    const nextQuestionSlug = _.get(EXTENSIONS_FLOW_COLLECTION_TYPES_TO_NEXT_QUESTION, collectionType);
    return nextQuestionSlug;
  };
